import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import formatTextToList from "../../../../utils/Fungsi/FormatTextToList";
import TitleSection from "../../../../components/TitleSection";

const MaknaLogoYayasan = () => {
  const [maknaBentuk, setMaknaBentuk] = useState("");
  const [maknaWarna, setMaknaWarna] = useState("");

  const [show, setShow] = useState(false);
  const [file, setFile] = useState(null);
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Remove the duplicate declaration of handleFileChange
  const [originalMaknaWarna, setOriginalMaknawarna] = useState("");
  const [originalMaknaBentuk, setOriginalMaknaBentuk] = useState("");

  const [maknaWarnaError, setMaknaWarnaError] = useState("");
  const [maknaBentukError, setMaknaBentukError] = useState("");
  const [fileError, setFileError] = useState("");

  const validateInput = () => {
    let isValid = true;
    setMaknaWarnaError("");
    setMaknaBentukError("");

    if (maknaWarna.trim() === "") {
      setMaknaWarnaError("Makna warna tidak boleh kosong.");
      isValid = false;
    }

    if (maknaBentuk.trim() === "") {
      setMaknaBentukError("Makna Bentuk tidak boleh kosong");
      isValid = false;
    }

    if (file) {
      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        setFileError(
          "Tipe file tidak valid. Hanya JPEG, JPG, dan PNG yang diperbolehkan."
        );
        isValid = false;
      }

      if (file.size > 5 * 1024 * 1024) {
        setFileError("Ukuran file harus kurang dari 5 MB.");
        isValid = false;
      }
    }
    return isValid;
  };

  const hasChange = () => {
    return (
      maknaWarna !== originalMaknaWarna ||
      maknaBentuk !== originalMaknaBentuk ||
      file !== null
    );
  };

  

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setMaknaWarnaError("");
    setMaknaBentukError("");
    setFileError("");

    setMaknaWarna(originalMaknaWarna);
    setMaknaBentuk(originalMaknaBentuk);
    setFile(null);
  };

  useEffect(() => {
    getMaknaLogo();
  }, []);

  const getMaknaLogo = async () => {
    setError("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/filosofi-lambang-yayasan/1`
      );
      setMaknaWarna(response.data.data.makna_warna || "");
      setMaknaBentuk(response.data.data.makna_bentuk || "");

      setOriginalMaknaBentuk(response.data.data.makna_bentuk || "");

      setOriginalMaknawarna(response.data.data.makna_warna || "");
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      setFileError("File harus kurang dari 5 MB");
    } else {
      setFileError("");
      setFile(selectedFile);
    }
    // console.log(e.target.files[0]);
  };

  const handleSave = async () => {
    setLoading(true);
    setError("");

    if (!hasChange()) {
      setError("Tidak ada perubahan yang dilakukan.");
      return;
    }

    if (!validateInput()) {
      return;
    }

    console.log(hasChange());

    const formData = new FormData();
    formData.append("makna_warna", maknaWarna);
    formData.append("makna_bentuk", maknaBentuk);

    if (file) {
      formData.append("logo_yayasan", file);
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/filosofi-lambang-yayasan/1`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Makna Logo berhasil diubah",
      });
      getMaknaLogo();
      handleClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "failed",
        title: "Gagal",
        text: "Makna Logo Gagal diubah! silagkan coba lagi",
      });
      setError(error.response?.data?.message || "Terjadi kesalahan");
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <TitleSection
            title="Makna Logo Yayasan"
            subtitle="Berikut adalah tampilan dari makna logo yayasan"
            onClick={handleShow}
            text="makna"
          />
        </div>
      </div>

      {/* Modal */}
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ubah Tampilan Makna Logo Yayasan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <img style={{ maxWidth: "150px" }} src={"../logo.svg"} alt="Logo" />
            <Form.Group className="mb-3 mt-4">
              <Form.Label>
                <h4 className="fw-semibold">Logo Yayasan</h4>
              </Form.Label>
              <Form.Control
                type="file"
                className={`form-control ${fileError ? "is-invalid" : ""}`}
                name="form-control"
                onChange={handleFileChange}
                accept="image/*"
              />
              {fileError && (
                <Form.Control.Feedback type="invalid">
                  {fileError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <div className="mb-3">
              <Alert variant="warning">
                <strong>Perhatian:</strong> Untuk makna warna dan makna bentuk,
                pisahkan setiap item dengan garis baru (Enter). Setiap item akan
                ditampilkan sebagai elemen daftar/list.
              </Alert>
            </div>
            <Form.Group className="mb-3">
              <Form.Label>
                <h4 className="fw-semibold">Makna Warna</h4>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                className={`form-control ${
                  maknaWarnaError ? "is-invalid" : ""
                }`}
                value={maknaWarna}
                onChange={(e) => setMaknaWarna(e.target.value)}
                placeholder="Masukkan makna warna, pisahkan setiap item dengan garis baru (Enter)..."
              />
              {maknaWarnaError && (
                <Form.Control.Feedback type="invalid">
                  {maknaWarnaError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <h4 className="fw-semibold">Makna Bentuk</h4>
              </Form.Label>
              <Form.Control
                as="textarea"
                className={`form-control ${
                  maknaBentukError ? "is-invalid" : ""
                }`}
                rows={5}
                value={maknaBentuk}
                onChange={(e) => setMaknaBentuk(e.target.value)}
                placeholder="Masukkan makna bentuk, pisahkan setiap item dengan garis baru (Enter)..."
              />
              {maknaBentukError && (
                <Form.Control.Feedback type="invalid">
                  {maknaBentukError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form>
          {error && <p className="text-danger mt-3">{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Tutup
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!hasChange() || loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Menyimpan
              </>
            ) : (
              "Simpan"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* end modal */}

      <div className="row">
        <div className="col-lg-4">
          <div className="card shadow mb-4">
            <div className="card-body text-center">
              <img
                style={{ maxWidth: "150px", background: "none" }}
                className="img-fluid"
                src={data.url_logo_yayasan}
                alt={data.logo_yayasan}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-8">
          <div className="card shadow mb-4">
            <div className="card-body">
              <section>
                <h3 className="card-title text-success">Makna Warna</h3>
                <ol className="list px-3">
                  {formatTextToList(data.makna_warna, "\n")}
                </ol>
              </section>
              <section>
                <h3 className="card-title text-success">Makna Bentuk</h3>
                <ol className="list px-3">
                  {formatTextToList(data.makna_bentuk, "\n")}
                </ol>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaknaLogoYayasan;
