import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import Breadcumbs from '../../components/Breadcumbs';
import Container from '../../components/Container';
import React from 'react';
const Layout = (props) => {

  return (
    <>
      <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <Header />
        <Sidebar />
        <div className="page-wrapper">
          <Breadcumbs />
          <Container>{props.children}</Container>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
