import React, { useEffect, useState } from "react";
import { Modal, Tabs, Tab, Button, Form, Spinner } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import formatText from "../../../../utils/Fungsi/FormatText";
import TitleSection from "../../../../components/TitleSection";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const SejarahLembaga = () => {
  // state data lembaga
  const [sejarahLembaga, setSejarahLembaga] = useState([]);
  const [idLembaga, setIdLembaga] = useState(
    localStorage.getItem("activeTab") || null
  );

  const [sejarahByIdLembaga, setSejarahByIdLembaga] = useState([]);
  const [isiSejarah, setIsiSejarah] = useState("");
  const [idSejarah, setIdSejarah] = useState(null);
  // state modal
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // State untuk menyimpan tab yang aktif
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || null
  );

  const [sejarahError, setSejarahError] = useState("");

  const validateInput = () => {
    let isValid = true;
    setSejarahError("");

    if (isiSejarah.trim() === "") {
      setSejarahError("Sejarah lembaga tidak boleh kosong.");
      isValid = false;
    }

    return isValid;
  };

  const handleShowModal = () => {
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  // jalankan fungsi get data sejarha lembaga
  useEffect(() => {
    getSejarahLembaga();
  }, []);

  // handle get data sejarah lembaga
  const getSejarahLembaga = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/sejarah-lembaga`
      );
      // console.log(response.data.data);
      setSejarahLembaga(response.data.data);
      if (response.data.data.length > 0 && !idLembaga) {
        setIdLembaga(response.data.data[0].id);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (idLembaga) {
      getSejarahByIdLembaga(idLembaga);
      localStorage.setItem("activeTab", idLembaga);
      setActiveTab(idLembaga);
    }
  }, [idLembaga]);

  // handle get data sejarah by id lembaga
  const getSejarahByIdLembaga = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/sejarah-lembaga/lembaga/${idLembaga}`
      );
      // console.log(response.data.data);
      setSejarahByIdLembaga(response.data.data);
      setIsiSejarah(response.data.data.isi_sejarah);
      setIdSejarah(response.data.data.id);
    } catch (error) {}
  };

  const handleSave = async () => {
    if (!validateInput()) {
      return;
    }

    setLoading(true);
    setError("");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/sejarah-lembaga/${idSejarah}`,
        {
          isi_sejarah: isiSejarah,
        }
      );
      Swal.fire({
        title: `Berhasil update sejarah ${sejarahByIdLembaga.nama_lembaga}`,
        icon: "success",
        text: response.data.message,
      });

      await getSejarahLembaga();
      await getSejarahByIdLembaga(idLembaga);
      setLoading(false);
      handleCloseModal();
      setError("");
      setActiveTab(idLembaga);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
      console.error(error.message);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs
            activeKey={activeTab || 1}
            onSelect={(k) => {
              setIdLembaga(k);
              setActiveTab(k);
            }}
          >
            {sejarahLembaga.map((item, index) => (
              <Tab key={item.id} eventKey={item.id} title={item.nama_lembaga}>
                <div className=" mt-3">
                  <TitleSection
                    title={`Sejarah Lembaga ${item.nama_lembaga}`}
                    subtitle={`Berikut adalah tampilan dari sejarah lembaga ${item.nama_lembaga}`}
                    onClick={handleShowModal}
                    text={"Sejarah"}
                  />
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="card-title mb-4 text-success">
                          Sejarah Lembaga {item.nama_lembaga}
                        </h4>
                        <div
                          style={{ textAlign: "justify" }}
                          className="text-dark"
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: item.isi_sejarah,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Ubah Sejarah {sejarahByIdLembaga?.nama_lembaga}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {sejarahByIdLembaga ? (
            <>
              <Form>
                <Form.Group>
                  <Form.Label>
                    <h4 className="fw-semibold">Isi Sejarah</h4>
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={isiSejarah}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setIsiSejarah(data);
                    }}
                    onBlur={(event, editor) => {
                      // Bisa digunakan untuk validasi atau event lain saat editor kehilangan fokus
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      // Bisa digunakan untuk event lain saat editor mendapatkan fokus
                      console.log("Focus.", editor);
                    }}
                  />
                  {sejarahError && (
                    <Form.Control.Feedback type="invalid">
                      {sejarahError}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Batal
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={loading}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Menyimpan
              </>
            ) : (
              "Simpan"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SejarahLembaga;
