const MenuSidebar = [
  {
    id: 1,
    title: 'Dashboard',
    path: '/dashboard',
  },
  {
    id: 2,
    title: 'Yayasan',
    submenu: [
      {
        id: 1,
        title: 'Banner Yayasan',
        path: '/yayasan/banner-yayasan',
      },
      {
        id: 2,
        title: 'Sambutan Yayasan',
        path: '/yayasan/sambutan-yayasan',
      },
      {
        id: 3,
        title: 'Sejarah Yayasan',
        path: '/yayasan/sejarah-yayasan',
      },
      {
        id: 4,
        title: 'Makna Logo Yayasan',
        path: '/yayasan/makna-logo-yayasan',
      },
      {
        id: 5,
        title: 'Visi Misi Yayasan',
        path: '/yayasan/visi-misi-yayasan',
      },
      {
        id: 6,
        title: 'Pendiri yayasan',
        path: '/yayasan/pendiri-yayasan',
      },
      {
        id: 7,
        title: 'Pengurus Yayasan',
        path: '/yayasan/pengurus-yayasan',
      },
      {
        id: 8,
        title: 'Agenda Yayasan',
        path: '/yayasan/agenda-yayasan',
      },
      {
        id: 9,
        title: 'Testimoni Yayasan',
        path: '/yayasan/testimoni-yayasan',
      },
    ],
  },
  {
    id: 3,
    title: 'Lembaga',
    submenu: [
      { id: 1, title: 'Data Lembaga', path: '/lembaga/data-lembaga' },
      { id: 2, title: 'Sambutan Lembaga', path: '/lembaga/sambutan-lembaga' },
      { id: 3, title: 'Sejarah Lembaga', path: '/lembaga/sejarah-lembaga' },
      { id: 4, title: 'Visi Misi Lembaga', path: '/lembaga/visi-misi-lembaga' },
      { id: 5, title: 'Makna Logo Lembaga', path: '/lembaga/makna-logo-lembaga' },
      { id: 6, title: 'Profile Lembaga', path: '/lembaga/profil-lembaga' },
      { id: 7, title: 'Dewan Guru', path: '/lembaga/dewan-guru' },
      { id: 8, title: 'Ekstrakurikuler Lembaga', path: '/lembaga/ekstrakurikuler-lembaga' },
    ],
  },
  {
    id: 4,
    title: 'Berita',
    path: '/berita',
  },
  {
    id: 5,
    title: 'Petugas',
    path: '/petugas',
  },
];

export default MenuSidebar;
