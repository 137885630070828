import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Alert, Spinner } from 'react-bootstrap';
import { AiOutlineUpload } from 'react-icons/ai'; // Import ikon
import Swal from 'sweetalert2';

const BannerYayasan = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [validationError, setValidationError] = useState('');
  const [file, setFile] = useState(null); // State to store the selected file

  const handleClose = () => setShow(false);
  const handleShow = (banner) => {
    setSelectedBanner(banner);
    setShow(true);
    setValidationError(''); // Reset error on modal open
    setFile(null); // Reset file when modal opens
  };

  useEffect(() => {
    fetch();
  }, []);

  const fetch = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/banner-yayasan`);
      setData(response.data.data);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file type
      if (!file.type.includes('image/jpeg') && !file.type.includes('image/png')) {
        setValidationError('File harus berupa gambar JPEG, PNG, atau JPG.');
        return;
      }
      // Check file size
      if (file.size > 5 * 1024 * 1024) {
        // 5 MB in bytes
        setValidationError('File harus kurang dari 5 MB.');
        return;
      }

      setValidationError('');
      setFile(file); // Store the selected file
    }
  };

  const handleSave = async () => {
    if (!file) {
      setValidationError('Pilih file gambar terlebih dahulu.');
      setLoading(false);
      return;
    }

    setLoading(true);

    try {
      const formData = new FormData();
      formData.append('gambar_hero_yayasan', file);
      await axios.put(`${process.env.REACT_APP_BASE_URL}/banner-yayasan/${selectedBanner.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      Swal.fire('Berhasil', 'Gambar berhasil diubah', 'success');
      fetch(); // Refresh data after saving
      handleClose();
    } catch (err) {
      
      Swal.fire('Gagal', 'Terjadi kesalahan saat mengubah gambar', 'error');
      setError(err.response.data.message);
    }
    setLoading(false);
  };

  if (error) return <div>Error: {error.message.toString()}</div>;


  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-12">
                  <h2 className="card-title fs-2 fw-bold text-success">Tampilan Banner Website</h2>
                  <h6 className="card-subtitle">Berikut adalah tampilan banner website yayasan</h6>
                  <div id="carouselExampleIndicators2" className="carousel slide" data-bs-ride="carousel">
                    <ol className="carousel-indicators">
                      {data.map((banner, index) => (
                        <li key={banner.id} data-bs-target="#carouselExampleIndicators2" data-bs-slide-to={index} className={index === 0 ? 'active' : ''} />
                      ))}
                    </ol>
                    <div className="carousel-inner" role="listbox">
                      {data.map((banner, index) => (
                        <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={banner.id}>
                          <img src={banner.url_resolusi_desktop} alt="banner" className="d-block w-100" />
                        </div>
                      ))}
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true" />
                      <span className="visually-hidden">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true" />
                      <span className="visually-hidden">Next</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-body">
              <h4 className="card-title fw-bold text-success">Kelola Banner Yayasan</h4>
              <h6 className="card-subtitle text-muted mb-4">Ubah sesuai kebutuhan</h6>
              <div className="table-responsive">
                <table className="table table-hover table-bordered">
                  <thead className="bg-dark text-white">
                    <tr>
                      <th style={{ width: '10px' }}>#</th>
                      <th>Banner</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody className="border border-light">
                    {data.map((banner, index) => (
                      <tr key={banner.id}>
                        <td className="align-middle">{index + 1}</td>
                        <td className="align-middle">
                          <img srcSet={banner.url_resolusi_desktop} alt="banner" className="img-fluid rounded-3" style={{ maxWidth: '150px' }} />
                        </td>
                        <td className="align-middle">
                          <button onClick={() => handleShow(banner)} className="btn btn-md btn-warning me-2">
                            <i className="fas fa-edit me-1"></i>Edit
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal untuk mengedit gambar */}
      <Modal
        size="lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ubah Gambar Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedBanner && (
            <div className="col-lg-12">
              <img src={selectedBanner.url_resolusi_desktop} alt="Selected Banner" className="img-fluid rounded-3 mb-3" style={{ maxWidth: '100%' }} />
              <div className="input-group mb-2">
                <input type="file" className="form-control" id="customFile" onChange={handleImageChange} />
                <label className="input-group-text" htmlFor="customFile">
                  <AiOutlineUpload size={24} />
                </label>
              </div>
              {validationError && <div className="text-danger mb-2">{validationError.toString()}</div>}
              <Alert variant="warning">
                <span className='fw-bold'>Perhatian:</span> Gambar harus memiliki orientasi <span className="fw-bold">Landscape</span> dengan resolusi<span className='fw-bold'> 1600px * 700px</span> format JPEG, JPG, atau PNG dengan ukuran kurang dari 5 MB.
              </Alert>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={loading}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Menyimpan
              </>
            ) : (
              "Simpan"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BannerYayasan;
