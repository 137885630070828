import Layout from './views/layouts/Layout';
import Login from './views/pages/auth/Login.jsx';
import Registrasi from './views/pages/auth/Registrasi.jsx';
import Dashboard from './views/pages/dashboard/index.js';
import { Route, Routes, BrowserRouter, Outlet } from 'react-router-dom';
import BannerYayasan from './views/pages/yayasan/banner/index.js';
import NotFound from './components/NotFound.js';
import PrivateRoute from './utils/ProtectedRoute.js';
import SambutanYayasan from './views/pages/yayasan/sambutan/index.js';
import VisiMisiYayasan from './views/pages/yayasan/visimisi/index.js';
import SejarahYayasan from './views/pages/yayasan/sejarah/index.jsx';
import PendiriYayasan from './views/pages/yayasan/pendiri/index.jsx';
import PengurusYayasan from './views/pages/yayasan/pengurus/index.jsx';
import MaknaLogoYayasan from './views/pages/yayasan/makna/index.jsx';
import DataLembaga from './views/pages/lembaga/data-lembaga/index.jsx';
import SejarahLembaga from './views/pages/lembaga/sejarah_lembaga/index.jsx';
import SambutanLembaga from './views/pages/lembaga/sambutan/index.jsx';
import VisiMisiLembaga from './views/pages/lembaga/visi-misi-lembaga/index.jsx';
import MaknaLogoLembaga from './views/pages/lembaga/makna-lembaga/index.jsx';
import ProfilLembaga from './views/pages/lembaga/profil-lembaga/index.jsx';
import EkstakurikulerLembaga from './views/pages/lembaga/ekstrakurikuler/index.jsx';
import Berita from './views/pages/berita/index.jsx';
import Users from './views/pages/users/index.jsx';
import DewanGuru from './views/pages/lembaga/dewan-guru/index.jsx';
import ScrollToTop from './utils/Fungsi/ScrollToTop.jsx';
import AgendaYayasan from './views/pages/yayasan/agenda/index.js';
import TestimoniYayasan from './views/pages/yayasan/testimoni/index.js';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Login />} />
        {/* <Route path="/registrasi" element={<Registrasi />} /> */}
        <Route path="/*" element={<NotFound />} />
        <Route
          element={
            <PrivateRoute>
              <LayoutWrapper />
            </PrivateRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />
          {/* Route Yayasan */}
          <Route path="/yayasan/banner-yayasan" element={<BannerYayasan />} />
          <Route path="/yayasan/sambutan-yayasan" element={<SambutanYayasan />} />
          <Route path="/yayasan/visi-misi-yayasan" element={<VisiMisiYayasan />} />
          <Route path="/yayasan/sejarah-yayasan" element={<SejarahYayasan />} />
          <Route path="/yayasan/pendiri-yayasan" element={<PendiriYayasan />} />
          <Route path="/yayasan/pengurus-yayasan" element={<PengurusYayasan />} />
          <Route path="/yayasan/makna-logo-yayasan" element={<MaknaLogoYayasan />} />
          <Route path='/yayasan/agenda-yayasan' element={<AgendaYayasan />} />
          <Route path='/yayasan/testimoni-yayasan' element={<TestimoniYayasan/>} />
          {/* Route Lembaga */}
          <Route path="/lembaga/data-lembaga" element={<DataLembaga />} />
          <Route path="/lembaga/sejarah-lembaga" element={<SejarahLembaga />} />
          <Route path="/lembaga/sambutan-lembaga" element={<SambutanLembaga />} />
          <Route path="/lembaga/visi-misi-lembaga" element={<VisiMisiLembaga />} />
          <Route path="/lembaga/makna-logo-lembaga" element={<MaknaLogoLembaga />} />
          <Route path="/lembaga/profil-lembaga" element={<ProfilLembaga />} />
          <Route path="/lembaga/dewan-guru" element={<DewanGuru />} />
          <Route path="/lembaga/ekstrakurikuler-lembaga" element={<EkstakurikulerLembaga />} />
          {/* Route Berita */}
          <Route path="/berita" element={<Berita />} />
          <Route path="/petugas" element={<Users />} />
        </Route>
        {/* user */}
      </Routes>
    </BrowserRouter>
  );
}

function LayoutWrapper() {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}

export default App;
