import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import $ from 'jquery';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import Swal from 'sweetalert2';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Berita = () => {
  const [berita, setBerita] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [kategoriOptions, setKategoriOptions] = useState([]);
  const [tipeKontenOptions, setTipeKontenOptions] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShowAddModal(false);
    setShowEditModal(false);
    resetForm();
  };

  const [formData, setFormData] = useState({
    id: '',
    id_kategori: '',
    id_tipe_konten: '',
    judul_konten: '',
    isi_konten: '',
    gambar_konten: null,
    gambar_konten_preview: null,
    tags: '',
  });

  const tableRef = useRef();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: files ? files[0] : value,
    }));
  };

  const getKategoriOption = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/kategori-berita`);
      setKategoriOptions(
        response.data.data.map((item) => ({
          value: item.id,
          label: item.nama,
        }))
      );
    } catch (error) {
      console.error('Error fetching kategori options:', error);
    }
  }, []);

  const getTipeKontenOption = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/tipe-konten`);
      setTipeKontenOptions(
        response.data.data.map((item) => ({
          value: item.id,
          label: item.tipe,
        }))
      );
    } catch (error) {
      console.error('Error fetching tipe konten options:', error);
    }
  }, []);

  const getBerita = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/beritas`);
      setBerita(response.data.data);
    } catch (error) {
      console.error('Error fetching berita:', error);
    }
  }, []);

  useEffect(() => {
    getBerita();
    getKategoriOption();
    getTipeKontenOption();
  }, [getBerita, getKategoriOption, getTipeKontenOption]);

  useEffect(() => {
    initializeDataTable();
  }, [berita]);

  const initializeDataTable = () => {
    if ($.fn.DataTable.isDataTable('#tableBerita')) {
      $(tableRef.current).DataTable().destroy();
    }

    $(tableRef.current).DataTable({
      data: berita,
      columns: [
        {
          title: 'No',
          data: null,
          render: (data, type, row, meta) => meta.row + 1,
          width: '5%',
          className: 'text-center',
        },
        {
          title: 'Gambar Konten',
          data: 'url_gambar_konten',
          render: (data) => `
      <img src="${data}" alt="gambar" width="100" style="object-fit: cover;" />
    `,
          className: 'text-center align-middle',
        },
        {
          title: 'Judul Konten',
          data: 'judul_konten',
          className: 'text-wrap align-middle',
        },
        {
          title: 'Isi Konten',
          data: 'isi_konten',
          render: (data) => (data.length > 50 ? `${data.substring(0, 100)}...` : data),
          className: 'text-wrap align-middle',
        },
        {
          title: 'Tipe Konten',
          data: 'tipe_konten',
          className: 'text-wrap align-middle',
        },
        {
          title: 'Kategori',
          data: 'kategori_konten',
          className: 'text-center',
        },
        {
          title: 'Tags',
          data: 'tags',
          render: function (data) {
            if (Array.isArray(data)) {
              return data.map((tag) => `<span class="badge bg-success me-1">${tag}</span>`).join(' ');
            } else if (typeof data === 'string') {
              return data
                .split(',')
                .map((tag) => `<span class="badge bg-success me-1">${tag.trim()}</span>`)
                .join(' ');
            } else {
              return `<span class="badge bg-secondary">No Tags</span>`;
            }
          },
          className: 'text-center',
        },
        {
          title: 'Aksi',
          data: null,
          render: (data, type, row) => `
          <div style="display: flex; justify-content: space-between; gap: 0.5rem;">
            <button class="btn btn-warning btn-sm edit-btn" data-id="${row.id}" style="flex: 1; max-width: 48%; display: flex; align-items: center; justify-content: center;">
              <i class="fas fa-edit" style="margin-right: 0.5rem;"></i> Edit
            </button>
            <button class="btn btn-danger btn-sm delete-btn" data-id="${row.id}" style="flex: 1; max-width: 48%; display: flex; align-items: center; justify-content: center;">
              <i class="fas fa-trash" style="margin-right: 0.5rem;"></i> Hapus
            </button>
          </div>
    `,
          className: 'text-center',
        },
      ],
    });

    $(tableRef.current).on('click', '.edit-btn', function () {
      const id = $(this).data('id');
      handleEdit(id);
    });

    $(tableRef.current).on('click', '.delete-btn', function () {
      const id = $(this).data('id');
      handleDelete(id);
    });
  };

  const handleEdit = (id) => {
    const item = berita.find((b) => b.id === id);
    if (item) {
      setFormData({
        id: item.id,
        id_kategori: item.id_kategori,
        id_tipe_konten: item.id_tipe_konten,
        judul_konten: item.judul_konten,
        isi_konten: item.isi_konten,
        gambar_konten: null,
        tags: item.tags,
        gambar_konten_preview: item.url_gambar_konten,
      });
      setShowEditModal(true);
    }
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Apakah anda yakin?',
        text: 'Data yang dihapus tidak dapat dikembalikan!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Batal',
        confirmButtonText: 'Ya, Hapus!',
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/beritas/${id}`);
        Swal.fire('Terhapus!', 'Data berita berhasil dihapus.', 'success');
        getBerita();
      }
    } catch (error) {
      Swal.fire('Error!', `Terjadi kesalahan: ${error.message}`, 'error');
      console.error('Error deleting berita:', error);
    }
  };

  const handleSubmit = async (e, isEdit = false) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        if (key !== 'id' && (formData[key] !== null || key === 'gambar_konten')) {
          formDataToSend.append(key, formData[key]);
        }
      }

      let response;
      if (isEdit) {
        response = await axios.put(`${process.env.REACT_APP_BASE_URL}/beritas/${formData.id}`, formDataToSend, { headers: { 'Content-Type': 'multipart/form-data' } });
      } else {
        response = await axios.post(`${process.env.REACT_APP_BASE_URL}/beritas`, formDataToSend, { headers: { 'Content-Type': 'multipart/form-data' } });
      }

      if (response.status === 200 || response.status === 201) {
        Swal.fire('Berhasil!', `Data berita berhasil ${isEdit ? 'diperbarui' : 'ditambahkan'}.`, 'success');
        setShowAddModal(false);
        setShowEditModal(false);
        resetForm();
        getBerita();
      }
    } catch (error) {
      Swal.fire('Error!', `Terjadi kesalahan: ${error.response.data.message}`, 'error');
      console.error('Error submitting berita:', error);
    }
  };

  const resetForm = () => {
    setFormData({
      id: '',
      id_kategori: '',
      id_tipe_konten: '',
      judul_konten: '',
      isi_konten: '',
      gambar_konten: null,
      gambar_konten_preview: null,
      tags: '',
    });
  };

  const renderForm = (isEdit = false) => (
    <Form onSubmit={(e) => handleSubmit(e, isEdit)}>
      <Form.Group className="mb-3">
        <Form.Label>
          <h4 className="fw-semibold">Tipe Konten</h4>
        </Form.Label>
        <Form.Control as="select" name="id_tipe_konten" value={formData.id_tipe_konten} onChange={handleChange} required>
          <option value="">Pilih Tipe Konten</option>
          {tipeKontenOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group className="mb-3">
        {formData.gambar_konten_preview ? (
          <Form.Group controlId="logoLembagaPreview">
            <img src={formData.gambar_konten_preview} alt="Foto Dewan Guru" className="img-fluid mb-3 rounded-3" style={{ maxWidth: '150px' }} />
          </Form.Group>
        ) : (
          ''
        )}
        <Alert variant="warning">
          <span className="fw-bold">Perhatian:</span> Gambar harus memiliki orientasi <span className="fw-bold">Landscape</span> dengan resolusi
          <span className="fw-bold"> 1600px * 700px</span> format JPEG, JPG, atau PNG dengan ukuran kurang dari 5 MB.
        </Alert>
        <Form.Label>
          <h4 className="fw-semibold">Gambar Konten</h4>
        </Form.Label>
        <Form.Control type="file" name="gambar_konten" onChange={handleChange} />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          <h4 className="fw-semibold">Judul Konten</h4>
        </Form.Label>
        <Form.Control type="text" name="judul_konten" value={formData.judul_konten} onChange={handleChange} required placeholder="Masukan judul artikel/berita" />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          <h4 className="fw-semibold">Isi Konten</h4>
        </Form.Label>
        <CKEditor
          editor={ClassicEditor}
          data={formData.isi_konten}
          onChange={(event, editor) => {
            const data = editor.getData();
            setFormData((prevState) => ({
              ...prevState,
              isi_konten: data,
            }));
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          <h4 className="fw-semibold">Kategori</h4>
        </Form.Label>
        <Form.Control as="select" name="id_kategori" value={formData.id_kategori} onChange={handleChange} required>
          <option value="">Pilih Kategori</option>
          {kategoriOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>
          <h4 className="fw-semibold">Tags</h4>
        </Form.Label>
        <Form.Control type="text" name="tags" value={formData.tags} onChange={handleChange} required placeholder="Masukan tags artikel/berita dipisahkan dengan koma" />
      </Form.Group>
      <Button variant="secondary me-2" onClick={() => handleClose()}>
        Batal
      </Button>
      <Button variant="primary" type="submit">
        {isEdit ? 'Update' : 'Simpan'}
      </Button>
    </Form>
  );

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Kelola Berita atau Artikel</h4>
              <h6 className="card-subtitle">Daftar Berita atau artikel</h6>
              <Button variant="primary mb-2" onClick={() => setShowAddModal(true)}>
                <i className="fas fa-plus me-2"></i>Tambah Berita
              </Button>
              <div
                className="table-responsive"
                style={{
                  width: '100%',
                  overflow: 'auto',
                  paddingBottom: '10px',
                }}
              >
                <table id="tableBerita" ref={tableRef} className="table table-bordered">
                  <thead className="bg-dark text-white">
                    <tr>
                      <th>No</th>
                      <th>Tipe Konten</th>
                      <th>Judul Konten</th>
                      <th>Isi Konten</th>
                      <th>Gambar Konten</th>
                      <th>Kategori</th>
                      <th>Tags</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody className="align-middle">{/* DataTable akan di-render di sini */}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={showAddModal}
        onHide={() => {
          setShowAddModal(false);
          resetForm();
        }}
      >
        {' '}
        <Modal.Header closeButton>
          <Modal.Title>Tambah Berita</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderForm()}</Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showEditModal}
        onHide={() => {
          setShowEditModal(false);
          resetForm();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Berita</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderForm(true)}</Modal.Body>
      </Modal>
    </div>
  );
};

export default Berita;
