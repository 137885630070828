import React, { useEffect, useRef, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import styles from "./style.module.css";

const Login = () => {
  const elementRef = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        elementRef.current.style.height = "100vh";
      } else {
        elementRef.current.style.height = "auto";
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const url = process.env.REACT_APP_BASE_URL;
  console.log(url)

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/login`,
        {
          email,
          password,
        },
        { withCredentials: true }
      );

      const token = response.data.access_token;
      localStorage.setItem("token", token);

      const decode = jwtDecode(token);
      localStorage.setItem("nama", decode.nama);
      localStorage.setItem("exp", decode.exp);
      localStorage.setItem("role", decode.role);

      navigate("/dashboard");
      window.location.reload();
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setMsg("Email dan password tidak valid!!");
      } else {
        setMsg("Terjadi kesalahan!! Coba lagi nanti ");
      }
    }
  };

  return (
    <div
      className="align-items-center row justify-content-center flex-md-column flex-lg-row d-flex border border-primary"
      style={{ height: "100vh" }}
    >
      <div className="col-lg-7 col-md-8 modal-bg-img">
        <picture>
          <img
            src="../assets/images/login.svg"
            alt="Gambar ilustarsi admin"
            style={{ width: "100%" }}
          />
        </picture>
      </div>
      <div
        className="col-12 col-lg-5 col-md-7 shadow-lg bg-white"
        ref={elementRef}
      >
        <div className="p-5 h-100 d-flex flex-column justify-content-center align-items-center">
          <div className="text-center mb-4">
            <img src="../assets/images/logo_yayasan.svg" alt="Logo yayasan" />
          </div>
          <h2 className="text-center mb-4">Sign In</h2>
          <form onSubmit={handleSubmit} className="w-100">
            {msg && <div className="alert alert-danger">{msg}</div>}
            <div className="mb-3 position-relative">
              <label className={styles.label} htmlFor="email">
                Email
              </label>
              <input
                type="email"
                className={`${styles.inputField} form-control`}
                id="email"
                placeholder="Masukan Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <i className={`${styles.inputIcon} bi bi-person`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-user-filled"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#2c3e50"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M12 2a5 5 0 1 1 -5 5l.005 -.217a5 5 0 0 1 4.995 -4.783z"
                    strokeWidth="0"
                    fill="currentColor"
                  />
                  <path
                    d="M14 14a5 5 0 0 1 5 5v1a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-1a5 5 0 0 1 5 -5h4z"
                    strokeWidth="0"
                    fill="currentColor"
                  />
                </svg>
              </i>
            </div>
            <div className="mb-3 position-relative">
              <label className={styles.label} htmlFor="password">
                Password
              </label>
              <input
                type="password"
                className={`${styles.inputField} form-control`}
                id="password"
                placeholder="Masukan Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <i className={`${styles.inputIcon} bi bi-lock`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-shield-lock-filled"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="#2c3e50"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path
                    d="M11.998 2l.118 .007l.059 .008l.061 .013l.111 .034a.993 .993 0 0 1 .217 .112l.104 .082l.255 .218a11 11 0 0 0 7.189 2.537l.342 -.01a1 1 0 0 1 1.005 .717a13 13 0 0 1 -9.208 16.25a1 1 0 0 1 -.502 0a13 13 0 0 1 -9.209 -16.25a1 1 0 0 1 1.005 -.717a11 11 0 0 0 7.531 -2.527l.263 -.225l.096 -.075a.993 .993 0 0 1 .217 -.112l.112 -.034a.97 .97 0 0 1 .119 -.021l.115 -.007zm.002 7a2 2 0 0 0 -1.995 1.85l-.005 .15l.005 .15a2 2 0 0 0 .995 1.581v1.769l.007 .117a1 1 0 0 0 1.993 -.117l.001 -1.768a2 2 0 0 0 -1.001 -3.732z"
                    strokeWidth="0"
                    fill="currentColor"
                  />
                </svg>
              </i>
            </div>
            <div className="text-center">
              <button type="submit" className={`${styles.button} w-100`}>
                LOGIN
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
