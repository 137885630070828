const formatTextToList = (text, slace) => {
  // Jika slace tidak didefinisikan, default ke '\n\n'
  const separator = slace || '\n\n';

  // Buat regex untuk separator
  const regex = new RegExp(separator, 'g');

  return (text || '').split(regex).map((paragraph, index) => (
    <li className="mb-2 text-dark" key={index}>
      {paragraph}
    </li>
  ));
};

export default formatTextToList;
