import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import TitleSection from "../../../../components/TitleSection";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const SejarahYayasan = () => {
  const [sejarahYayasan, setSejarahYayasan] = useState({ isi_sejarah: "" });
  const [newSejarah, setNewSejarah] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const [originalSejarah, setOriginialSejarah] = useState("");

  const [sejarahError, setSejarahError] = useState("");

  const validateInput = () => {
    // Remove the assignment since isValid is not being used
    let isValid = true;
    setSejarahError("");

    if (newSejarah.trim() === "") {
      setSejarahError("Sejarah yayasan tidak boleh kosong.");
      isValid = false;
    }

    return isValid;
  };

  const hasChange = () => {
    return originalSejarah !== newSejarah;
  };

  useEffect(() => {
    getSejarahYayasan();
  }, []);

  const getSejarahYayasan = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/sejarah-yayasan/1`
      );
      setSejarahYayasan(response.data);

      setNewSejarah(response.data.isi_sejarah || "");
      setOriginialSejarah(response.data.isi_sejarah || "");
    } catch (error) {
      console.error(error.message);
      setLoading(false);
      setError("Terjadi kesalahan saat mengambil data.");
    }
    setLoading(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSejarahError("");
    setNewSejarah(originalSejarah);
  };

  const handleOnHandleChange = (e) => {
    setNewSejarah(e.target.value);
  };

  const formatText = (text) => {
    return (text || "").split("\n").map((paragraph, index) => (
      <p style={{ textAlign: "justify" }} className="text-dark" key={index}>
        {paragraph}
      </p>
    ));
  };

  const handleSave = async () => {
    setLoading(true);
    setError("");

    if (!hasChange()) {
      setLoading(false);
      setError("Tidak ada perubahan yang disimpan.");
      return;
    }

    if (!validateInput()) {
      setLoading(false);
      return;
    }
    try {
      const formData = new FormData();
      formData.append("isi_sejarah", newSejarah);

      await axios.put(`${process.env.REACT_APP_BASE_URL}/sejarah-yayasan/1`, {
        isi_sejarah: newSejarah,
      });

      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Sejarah yayasan berhasil diubah",
      });

      getSejarahYayasan();
      handleClose();
    } catch (error) {
      console.error(error.message);
      setError(
        error.response?.data?.message || "Terjadi Kesalahan saat menyimpan data"
      );
    } finally {
      setLoading(false); // Pastikan setLoading(false) ada di sini
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <TitleSection
            title="Sejarah Yayasan"
            subtitle="Berikut adalah tampilan dari sejarah yayasan"
            onClick={handleShow}
            text="sejarah"
          />
        </div>
      </div>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Ubah Tampilan Sejarah Yayasan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <p className="alert alert-danger">{error}</p>}
          <Form>
            <Form.Group controlId="formSejarah" className="mb-3">
              <Form.Label>
                <h4 className="fw-semibold">Sejarah Yayasan</h4>
              </Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={newSejarah}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  // Asumsi bahwa handleOnHandleChange menerima event dengan target.value
                  handleOnHandleChange({
                    target: { value: data, id: "formSejarah" },
                  });
                }}
              />
              {sejarahError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {sejarahError}
                </div>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Batal
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!hasChange() || loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Menyimpan
              </>
            ) : (
              "Simpan"
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="col-lg-12">
        <div className="card shadow mb-4">
          <div className="card-body">
            <h4 className="card-title font-weight-bold text-success fw-semibold">
              Sejarah Yayasan
            </h4>
            {loading ? (
              <p className="text-muted">Loading...</p>
            ) : error ? (
              <div className="alert alert-danger">{error}</div>
            ) : (
              <div>
                {sejarahYayasan.isi_sejarah ? (
                  <div style={{ textAlign: "justify" }} className="text-dark">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: sejarahYayasan.isi_sejarah,
                      }}
                    />
                  </div>
                ) : (
                  <div className="alert alert-danger">
                    Data tidak ditemukan.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SejarahYayasan;
