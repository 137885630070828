/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useRef, useEffect } from "react";
import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import "datatables.net-bs5";
import $ from "jquery";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import axios from "axios";
import Swal from "sweetalert2";
import { set } from "date-fns";

const TestimoniYayasan = () => {
  const [testimoni, setTestimoni] = useState([]);
  const [idTestimoni, setIdTestimoni] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [namaError, setNamaError] = useState("");
  const [deskripsiError, setDeskripsiError] = useState("");
  const [peranError, setPeranError] = useState("");
  const [fotoTestimoniError, setFotoTestimoniError] = useState("");

  const validateInput = () => {
    let isValid = true;

    if (formData.nama.trim() === "") {
      setNamaError("Nama testimoni tidak boleh kosong");
      isValid = false;
    }

    if (formData.deskripsi.trim() === "") {
      setDeskripsiError("Deskripsi testimoni tidak boleh kosong");
      isValid = false;
    }

    if (formData.peran.trim() === "") {
      setPeranError("Peran testimoni tidak boleh kosong");
      isValid = false;
    }

    return isValid;
  };

  const [formData, setFormData] = useState({
    nama: "",
    deskripsi: "",
    peran: "",
    foto_testimoni: null,
    foto_testimoni_preview: null,
  });

  const [currentPage] = useState(1);

  const tableRef = useRef();
  const dataTableRef = useRef();

  const getDataTestimoni = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/testimonis`,
        {
          params: {
            page: currentPage,
            limit: 10,
          },
        }
      );

      setTestimoni(response.data.data);
    } catch (error) {
      setError(error.response?.data?.message || "Error fetching data");
      console.log(error.response?.data?.message || error.message);
    }
    setLoading(false);
  }, [currentPage]);

  useEffect(() => {
    getDataTestimoni();
  }, [getDataTestimoni]);

  useEffect(() => {
    if (!show) {
      getDataTestimoni();
    }
  }, [show, getDataTestimoni]);

  useEffect(() => {
    if (dataTableRef.current) {
      dataTableRef.current.destroy();
      initializeDatatable();
    } else {
      initializeDatatable();
    }
  }, [testimoni]);

  const initializeDatatable = () => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable().clear().destroy();
    }

    dataTableRef.current = $(tableRef.current).DataTable({
      data: testimoni,
      columns: [
        {
          title: "No",
          data: null,
          render: (data, type, row, meta) => meta.row + 1,
          width: "5%",
          className: "text-center", // Menambahkan class untuk menengahkan teks
        },
        {
          title: "Nama",
          data: "nama",
        },
        {
          title: "Deskripsi",
          data: "deskripsi",
          render: function (data) {
            const maxLength = 100;
            if (data.length > maxLength) {
              return `${data.substring(0, maxLength)}...`;
            }
            return data;
          },
        },
        {
          title: "Alumni Lembaga",
          data: "peran",
        },
        {
          title: "Foto Testimoni",
          data: "url_foto_testimoni",
          render: function (data, type, row) {
            const defaultImage =
              "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y&s=200";
            const imageUrl = data ? data : defaultImage;

            return `
        <img src="${imageUrl}" alt="Foto ${row.nama}" 
             width="50" height="50" 
             class="rounded-circle" 
             style="object-fit: cover;" />
      `;
          },
        },
        {
          title: "Aksi",
          data: null,
          render: (data, type, row) => {
            return `
        <div class="btn-group">
          <button class="btn btn-primary btn-sm me-2 edit-btn" data-id="${row.id}">
            <i class="fas fa-edit"></i> Edit
          </button>
          <button class="btn btn-danger btn-sm delete-btn" data-id="${row.id}">
            <i class="fas fa-trash"></i> Hapus
          </button>
        </div>
      `;
          },
        },
      ],
    });

    $(tableRef.current).on("click", ".edit-btn", function () {
      const id = $(this).data("id");
      const item = testimoni.find((d) => d.id === id);
      if (item) {
        handleEdit(item);
      }
    });

    $(tableRef.current).on("click", ".delete-btn", function () {
      const id = $(this).data("id");
      handleDelete(id);
    });
  };

  const handleShowModal = () => setShow(true);
  const handleCloseModal = () => {
    setShow(false);
    setIdTestimoni("");
    setError("");
    setNamaError("");
    setDeskripsiError("");
    setPeranError("");
    setFotoTestimoniError("");

    setFormData({
      nama: "",
      deskripsi: "",
      peran: "",
      foto_testimoni: null,
      foto_testimoni_preview: null,
    });
  };

  const handleSave = async () => {
    if (!validateInput()) {
      return;
    }
    setLoading(true);
    if (!formData.nama || !formData.deskripsi || !formData.peran) {
      setError("Semua field harus diisi!");
      return;
    }
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("nama", formData.nama);
      formDataToSend.append("deskripsi", formData.deskripsi);
      formDataToSend.append("peran", formData.peran);

      if (formData.foto_testimoni instanceof File) {
        formDataToSend.append("foto_testimoni", formData.foto_testimoni);
      } else if (formData.foto_testimoni_preview === null && idTestimoni) {
        // Use a special value to indicate deletion, like an empty string or a specific placeholder
        formDataToSend.append("foto_testimoni", "DELETE");
      }
      if (idTestimoni) {
        await axios.put(
          `${process.env.REACT_APP_BASE_URL}/testimonis/${idTestimoni}`,
          formDataToSend
        );

        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Testimoni Berhasil Di Ubah!",
        });
      } else {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/testimonis`,
          formDataToSend
        );
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Tetimoni Berhasil di Tambahkan!",
        });
      }
      handleCloseModal();
      getDataTestimoni();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: `${error.response.data.message}`,
      });
      console.error("Terjadi kesalahan saat menyiman:", error);
    }
    setLoading(false);
  };

  const handleEdit = (item) => {
    setIdTestimoni(item.id);
    setFormData({
      nama: item.nama,
      deskripsi: item.deskripsi,
      peran: item.peran,
      foto_testimoni: null || "",
      foto_testimoni_preview: item.url_foto_testimoni || "",
    });
    setShow(true);
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Apakah anda yakin?",
        text: "Apakah anda yakin akan menghapus testimoni ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
      });

      if (result.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/testimonis/${id}`
        );
        Swal.fire({
          icon: "success",
          title: "Terhapus",
          text: "Testimoni berhasil dihapus!",
        });
        getDataTestimoni();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Terjadi kesalahan: ${
          error.response?.data?.message || error.message
        }`,
      });
      console.error("Error deleting testimoni:", error);
    }
  };

  const handleDeletePhoto = () => {
    setFormData({
      ...formData,
      foto_testimoni: null,
      foto_testimoni_preview: null,
    });
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <h2 className="text-success fw-bold">Kelola Testimoni Yayasan</h2>
            <h6 className="text-muted">
              Berikut daftar testimoni yayasan yang telah ditambahkan
            </h6>
            <Button
              variant="primary"
              className="mb-4"
              onClick={handleShowModal}
            >
              <i className="fas fa-plus me-2"></i> Tambah Testimoni
            </Button>

            <div className="table-responsive">
              <div>
                <table
                  ref={tableRef}
                  id="testimoniTable"
                  className="table table-striped"
                >
                  <thead className="bg-dark text-white border">
                    <tr>
                      <th>No</th>
                      <th>Nama</th>
                      <th>Deskripsi</th>
                      <th>Alumni Lembaga</th>
                      <th>Foto Testimoni</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody className="align-middle">
                    {/* DataTables will automatically fill this */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleCloseModal}>
        <Modal.Header>
          <Modal.Title>{idTestimoni ? "Edit" : "Tambah"} Testimoni</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                {" "}
                <h4 className="fw-semibold">Nama Testimoner</h4>
              </Form.Label>
              <Form.Control
                type="text"
                value={formData.nama}
                className={`form-control ${namaError ? "is-invalid" : ""}`}
                required
                placeholder="Masukan nama testimoni"
                onChange={(e) =>
                  setFormData({ ...formData, nama: e.target.value })
                }
              />
              {namaError && (
                <Form.Control.Feedback type="invalid">
                  {namaError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                {" "}
                <h4 className="fw-semibold">Deskripsi</h4>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                className={`form-conctrol ${
                  deskripsiError ? "is-invalid" : ""
                }`}
                value={formData.deskripsi}
                placeholder="Masukan deskripsi testimoni"
                required
                onChange={(e) =>
                  setFormData({ ...formData, deskripsi: e.target.value })
                }
              />
              {deskripsiError && (
                <Form.Control.Feedback type="invalid">
                  {deskripsiError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                {" "}
                <h4 className="fw-semibold">Status</h4>
              </Form.Label>
              <Form.Control
                type="text"
                value={formData.peran}
                className={`form-control ${peranError ? "is-invalid" : ""}`}
                required
                placeholder="Masukan peran alumni"
                onChange={(e) =>
                  setFormData({ ...formData, peran: e.target.value })
                }
              />
              {peranError && (
                <Form.Control.Feedback type="invalid">
                  {peranError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              {formData.foto_testimoni_preview && (
                <div className="mt-2 mb-3 d-flex align-items-center">
                  <img
                    src={formData.foto_testimoni_preview}
                    alt="Foto Testimoni"
                    width="100"
                    height="100"
                    className="img-fluid rounded-3 border border-secondary"
                  />
                  <Button
                    variant="danger"
                    size="sm"
                    className="ms-3"
                    onClick={handleDeletePhoto}
                  >
                    Hapus Foto
                  </Button>
                </div>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                {" "}
                <h4 className="fw-semibold">Foto Testimoni</h4>
              </Form.Label>
              <Form.Control
                type="file"
                className="form-control"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    foto_testimoni: e.target.files[0],
                    foto_testimoni_preview: URL.createObjectURL(
                      e.target.files[0]
                    ),
                  });
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Batal
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={loading}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Menyimpan
              </>
            ) : (
              "Simpan"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TestimoniYayasan;
