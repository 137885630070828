import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";

const Users = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [formData, setFormData] = useState({
    nama: "",
    email: "",
    password: "",
    konfirmasi_password: "",
    role: "petugas",
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    getDataUsers();
  }, []);

  const getDataUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/users`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setData(response.data.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    resetForm();
  };

  const resetForm = () => {
    setFormData({
      nama: "",
      email: "",
      password: "",
      konfirmasi_password: "",
      role: "petugas",
    });
    setIsEdit(false);
    setSelectedUserId(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSave = async () => {
    if (formData.password !== formData.konfirmasi_password) {
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: "Password dan konfirmasi password tidak cocok",
      });
      return;
    }

    try {
      if (isEdit) {
        await axios.put(
          `${process.env.REACT_APP_BASE_URL}/users/${selectedUserId}`,
          formData,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Petugas berhasil diperbarui",
        });
      } else {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/registrasi`,
          formData,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Petugas berhasil ditambahkan",
        });
      }
      handleClose();
      getDataUsers();
    } catch (error) {
      console.error(error.message);
      const response = error.response;
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: response.data.message,
      });
    }
  };

  const handleEdit = (user) => {
    setFormData({
      nama: user.nama,
      email: user.email,
      password: "",
      konfirmasi_password: "",
      role: user.role,
    });
    setSelectedUserId(user.id);
    setIsEdit(true);
    handleShow();
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/users/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      Swal.fire({
        showConfirmButton: true,
        
        icon: "success",
        title: "Berhasil",
        text: "Petugas berhasil dihapus",
      });
      getDataUsers();
    } catch (error) {
      console.error(error.message);
      const response = error.response;
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: response.data.message,
      });
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="card-title">Kelola Petugas</div>
              <div className="card-subtitle">Berikut daftar petugas:</div>
              <Button variant="primary" onClick={handleShow}>
                <i className="fas fa-plus me-2"></i> Tambah Petugas
              </Button>
              <div className="table-responsive mt-3">
                <table className="table table-striped">
                  <thead className="bg-dark border-dark text-light">
                    <tr>
                      <td>No</td>
                      <td>Nama</td>
                      <td>Email</td>
                      <td>Role</td>
                      <td>Aksi</td>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>{item.nama}</td>
                        <td>{item.email}</td>
                        <td>{item.role}</td>
                        <td className="align-middle">
                          <div className="d-flex justify-content-evenly">
                            {/* <Button
                              variant="warning"
                              onClick={() => handleEdit(item)}
                            >
                              <i className="fas fa-edit me-2"></i>Edit
                            </Button> */}
                            <Button
                              variant="danger"
                              onClick={() => handleDelete(item.id)}
                            >
                              <i className="fas fa-trash me-2"></i>Hapus
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>{isEdit ? "Edit Petugas" : "Tambah Petugas"}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <Form.Group className="mb-3">
              <Form.Label>Nama Petugas</Form.Label>
              <Form.Control
                type="text"
                name="nama"
                value={formData.nama}
                onChange={handleChange}
                placeholder="Masukkan nama petugas"
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Masukkan email"
                autoComplete="off"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                placeholder="Masukkan password"
                autoComplete="new-password"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Konfirmasi Password</Form.Label>
              <Form.Control
                type="password"
                name="konfirmasi_password"
                value={formData.konfirmasi_password}
                onChange={handleChange}
                placeholder="Konfirmasi password"
                autoComplete="new-password"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Role</Form.Label>
              <div>
                <Form.Check
                  type="radio"
                  label="Admin"
                  name="role"
                  value="admin"
                  id="roleAdmin"
                  checked={formData.role === "admin"}
                  onChange={handleChange}
                />
                <Form.Check
                  type="radio"
                  label="Petugas"
                  name="role"
                  value="petugas"
                  id="rolePetugas"
                  checked={formData.role === "petugas"}
                  onChange={handleChange}
                />
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {isEdit ? "Perbarui" : "Tambahkan"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Users;
