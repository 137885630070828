import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import MenuSidebar from '../utils/MenuSidebar';

const Seidebar = () => {
  const location = useLocation();
  const [path, setPath] = useState(location.pathname);
  const [activeMenu, setActiveMenu] = useState('');
  const navigate = useNavigate();

  // menu sidebar
  const { submenu: submenuYayasan } = MenuSidebar.find((m) => m.id === 2);
  const { submenu: submenuLembaga } = MenuSidebar.find((m) => m.id === 3);

  const logout = async () => {
    try {
      localStorage.clear('token');
      navigate('/');
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const isActive = (menu) => {
    return activeMenu === menu ? 'active' : '';
  };

  const isSelected = (menu) => {
    return activeMenu === menu ? 'selected' : '';
  };

  return (
    <div>
      <aside className="left-sidebar" data-sidebarbg="skin6">
        <div className="scroll-sidebar" data-sidebarbg="skin6">
          <nav className="sidebar-nav">
            <ul id="sidebarnav">
              {/* section dashboard start */}
              <li className={`sidebar-item ${isSelected('/dashboard')}`}>
                <Link className={`sidebar-link sidebar-link ${isActive('/dashboard')}`} to="/dashboard" aria-expanded="false" onClick={() => handleMenuClick('/dashboard')}>
                  <i data-feather="home" className="feather-icon" />
                  <span className="hide-menu">Dashboard</span>
                </Link>
              </li>
              {/* section dashboard end */}
              <li className="list-divider" />
              <li className="nav-small-cap">
                <span className="hide-menu">Kelola Website</span>
              </li>
              {/* section yayasan start*/}
              <li className={`sidebar-item ${isSelected('/yayasan')}`}>
                <a className={`sidebar-link has-arrow ${isActive('/yayasan')}`} href="/#" aria-expanded="false" onClick={() => handleMenuClick('/yayasan')}>
                  <i data-feather="file-text" className="feather-icon" />
                  <span className="hide-menu">Yayasan</span>
                </a>
                <ul className="collapse first-level base-level-line">
                  {submenuYayasan.map((item, index) => (
                    <li className={`sidebar-item`} key={index}>
                      <Link to={item.path} className={`sidebar-link`}>
                        <span className="hide-menu">{item.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              {/* section yayasan end*/}

              {/* section lembaga start*/}
              <li className={`sidebar-item ${isSelected('/lembaga')}`}>
                <a className={`sidebar-link has-arrow ${isActive('/lembaga')}`} href="/#" aria-expanded="false" onClick={() => handleMenuClick('/lembaga')}>
                  <i data-feather="grid" className="feather-icon" />
                  <span className="hide-menu">Lembaga</span>
                </a>
                <ul className="collapse first-level base-level-line">
                  {submenuLembaga.map((item, index) => (
                    <li className="sidebar-item" key={index}>
                      <Link to={item.path} className="sidebar-link">
                        <span className="hide-menu">{item.title}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
              {/* section lembaga end*/}

              {/* section berita start*/}
              <li className={`sidebar-item ${isSelected('/berita')}`}>
                <Link className={`sidebar-link sidebar-link ${isActive('/berita')}`} to={'/berita'} aria-expanded="false" onClick={() => handleMenuClick('/berita')}>
                  <i data-feather="sidebar" className="feather-icon" />
                  <span className="hide-menu">Berita</span>
                </Link>
              </li>
              {/* section berita end*/}

              <li className="list-divider" />
              <li className="nav-small-cap">
                <span className="hide-menu">Data Petugas</span>
              </li>
              <li className={`sidebar-item ${isSelected('/petugas')}`}>
                <Link className={`sidebar-link sidebar-link ${isActive('/petugas')}`} to={'/petugas'} aria-expanded="false" onClick={() => handleMenuClick('/petugas')}>
                  <i data-feather="users" className="feather-icon" />
                  <span className="hide-menu">Petugas</span>
                </Link>
              </li>
              {/* <li className="sidebar-item">
                <Link className="sidebar-link sidebar-link" to={'/registrasi'} aria-expanded="false">
                  <i data-feather="plus" className="feather-icon" />
                  <span className="hide-menu">Tambah Petugas</span>
                </Link>
              </li> */}
              <li className="list-divider mb-2" />
              <li className="sidebar-item">
                <button className="sidebar-link sidebar-link border-0 bg-transparent" aria-expanded="false" onClick={logout}>
                  <i data-feather="log-out" className="feather-icon" />
                  <span className="hide-menu">Logout</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Seidebar;
