import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import formatText from "../../../../utils/Fungsi/FormatText";
import TitleSection from "../../../../components/TitleSection";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const SambutanYayasan = () => {
  const [sambutan, setSambutan] = useState({});
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [nama, setNama] = useState("");
  const [jabatan, setJabatan] = useState("");
  const [newText, setNewText] = useState("");
  const [file, setFile] = useState(null);

  const [originalNama, setOriginalNama] = useState("");
  const [originalJabatan, setOriginalJabatan] = useState("");
  const [originalText, setOriginalText] = useState("");

  const [namaError, setNamaError] = useState("");
  const [jabatanError, setJabatanError] = useState("");
  const [sambutanError, setSambutanError] = useState("");
  const [fileError, setFileError] = useState("");

  useEffect(() => {
    getSambutan();
  }, []);

  const getSambutan = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/pidato-yayasan/1`
      );
      const data = response.data.data;
      setSambutan(data);
      setNama(data.nama || "");
      setJabatan(data.jabatan || "");
      setNewText(data.isi_sambutan || "");

      setOriginalNama(data.nama || "");
      setOriginalJabatan(data.jabatan || "");
      setOriginalText(data.isi_sambutan || "");
    } catch (err) {
      console.error(err.response?.data?.message || "An error occurred");
      setError("Failed to fetch data. Please try again.");
    }
  };

  const validateInputs = () => {
    let isValid = true;
    setNamaError("");
    setJabatanError("");
    setSambutanError("");
    setFileError("");

    if (nama.trim() === "") {
      setNamaError("Nama tidak boleh kosong");
      isValid = false;
    }

    if (jabatan.trim() === "") {
      setJabatanError("Jabatan tidak boleh kosong");
      isValid = false;
    }

    if (newText.trim() === "") {
      setSambutanError("Isi sambutan tidak boleh kosong");
      isValid = false;
    }

    if (file) {
      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        setFileError(
          "Tipe file tidak valid. Hanya JPEG, JPG, dan PNG yang diperbolehkan."
        );
        isValid = false;
      }

      if (file.size > 5 * 1024 * 1024) {
        setFileError("Ukuran file harus kurang dari 5 MB.");
        isValid = false;
      }
    }

    return isValid;
  };

  const hasChanges = () => {
    return (
      nama !== originalNama ||
      jabatan !== originalJabatan ||
      newText !== originalText ||
      file !== null
    );
  };

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setError("");
    setNamaError("");
    setJabatanError("");
    setSambutanError("");
    setFileError("");
    // Reset to original values
    setNama(originalNama);
    setJabatan(originalJabatan);
    setNewText(originalText);
    setFile(null);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      setFileError("Ukuran file tidak boleh lebih dari 5 MB");
    } else {
      setFileError("");
      setFile(selectedFile);
    }
  };

  const handleSave = async () => {
    setLoading(true);
    setError("");
    if (!hasChanges()) {
      setError("Tidak ada perubahan yang dilakukan.");
      return;
    }

    if (!validateInputs()) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("nama", nama);
      formData.append("jabatan", jabatan);
      formData.append("isi_sambutan", newText);
      if (file) {
        formData.append("gambar_sambutan_yayasan", file);
      }
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}/pidato-yayasan/1`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: "Sambutan yayasan berhasil diperbarui.",
      });

      getSambutan();
      handleClose();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: err.response?.data?.message || "Terjadi kesalahan saat menyimpan.",
      })
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <TitleSection
            title="Sambutan Yayasan"
            subtitle="Berikut adalah tampilan dari Sambutan yayasan"
            onClick={handleShow}
            text="sambutan"
          />
        </div>
        <div className="col-lg-4">
          <div className="card shadow mb-4">
            <div className="card-body text-center">
              <picture>
                <source
                  srcSet={sambutan.url_resolusi_tablet}
                  media="(min-width: 768px)"
                />
                <source
                  srcSet={sambutan.url_resolusi_mobile}
                  media="(min-width: 400px)"
                />
                <img
                  src={sambutan.url_resolusi_desktop}
                  className="img-fluid rounded-3"
                  alt="Ketua Yayasan"
                />
              </picture>
              <h4 className="text-dark font-weight-bold mt-2 fw-semibold">
                {sambutan.nama}
              </h4>
              <p className="text-success">{sambutan.jabatan}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-8">
          <div className="card shadow mb-4">
            <div className="card-body">
              <h3 className="text-success fw-semibold font-weight-bold">
                Sambutan Ketua Yayasan
              </h3>
              <div style={{textAlign: "justify"}} className="text-dark">
                <span
                  dangerouslySetInnerHTML={{ __html: sambutan.isi_sambutan }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal size="lg" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Sambutan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {error && <Alert variant="danger">{error}</Alert>}

            <Form.Group controlId="formFile" className="mb-3">
              <div>
                <img
                  src={sambutan.url_resolusi_desktop}
                  alt="banner"
                  className="img-fluid rounded-3 mb-2"
                  style={{ maxWidth: "150px" }}
                />
              </div>
              <Alert variant="warning">
                <span className="fw-bold">Perhatian:</span> Gambar harus
                memiliki orientasi potrait dengan ukuran {""}
                <span className="fw-bold">1016px * 1352px</span> dan berformat
                JPEG, JPG, atau PNG dengan ukuran kurang dari 5 MB.
              </Alert>
              <Form.Label>
                <h4 className="fw-semibold">Ubah Gambar Ketua Yayasan </h4>
              </Form.Label>
              <Form.Control
                type="file"
                onChange={handleFileChange}
                accept="image/*"
              />
              {fileError && (
                <Form.Text className="text-danger">{fileError}</Form.Text>
              )}
            </Form.Group>
            <Form.Group controlId="formNama" className="mb-3">
              <Form.Label>
                {" "}
                <h4 className="fw-semibold">Nama Ketua Yayasan</h4>
              </Form.Label>
              <Form.Control
                placeholder="Masukan Nama"
                className={`form-control ${namaError ? "is-invalid" : ""}`}
                value={nama}
                onChange={(e) => setNama(e.target.value)}
              />
              {namaError && (
                <Form.Control.Feedback type="invalid">
                  {namaError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group controlId="formJabatan" className="mb-3">
              <Form.Label>
                <h4 className="fw-semibold">Jabatan</h4>
              </Form.Label>
              <Form.Control
                className={`form-control ${jabatanError ? "is-invalid" : ""}`}
                value={jabatan}
                placeholder="Masukan Jabatan"
                onChange={(e) => setJabatan(e.target.value)}
              />
              {jabatanError && (
                <Form.Control.Feedback type="invalid">
                  {jabatanError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group controlId="formSambutan" className="mb-3">
              <Form.Label>
                <h4 className="fw-semibold">Isi Sambutan</h4>
              </Form.Label>
              <CKEditor
                editor={ClassicEditor}
                data={newText}
                className={`form-control ${sambutanError ? "is-invalid" : ""}`}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setNewText(data);
                }}
              />
              {sambutanError && (
                <div className="invalid-feedback" style={{ display: "block" }}>
                  {sambutanError}
                </div>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Tutup
          </Button>
          <Button
            variant="primary"
            onClick={handleSave}
            disabled={!hasChanges() || loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Menyimpan
              </>
            ) : (
              "Simpan"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SambutanYayasan;
