import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Form, Button, Alert, Spinner } from "react-bootstrap";
import { AiOutlineUpload } from "react-icons/ai";
import Swal from "sweetalert2";

const PendiriYayasan = () => {
  const [namaPendiri, setNamaPendiri] = useState("");
  const [jabatanPendiri, setJabatanPendiri] = useState("");
  const [file, setFile] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedPendiri, setSelectedPendiri] = useState(null);

  const [originalNamaPendiri, setOriginalNamaPendiri] = useState("");
  const [originalJabatanPendiri, setOriginalJabatanPendiri] = useState("");

  const [namaPendiriError, setNamaPendiriError] = useState("");
  const [jabatanPendiriError, setJabatanPendiriError] = useState("");
  const [fileError, setFileError] = useState("");

  const [isChanged, setIsChanged] = useState(false);

  const validateInput = () => {
    let isValid = true;
    setNamaPendiriError("");
    setJabatanPendiriError("");
    setFileError("");

    if (namaPendiri.trim() === "") {
      setNamaPendiriError("Nama Pendiri tidak boleh kosong.");
      isValid = false;
    }

    if (jabatanPendiri.trim() === "") {
      setJabatanPendiriError("Jabatan Pendiri tidak boleh kosong.");
      isValid = false;
    }

    if (file) {
      const validTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!validTypes.includes(file.type)) {
        setFileError(
          "Tipe file tidak valid. Hanya JPEG, JPG, dan PNG yang diperbolehkan."
        );
        isValid = false;
      }

      if (file.size > 5 * 1024 * 1024) {
        setFileError("Ukuran file harus kurang dari 5 MB.");
        isValid = false;
      }
    }
    return isValid;
  };

  const hasChange = () => {
    const changed =
      namaPendiri !== originalNamaPendiri ||
      jabatanPendiri !== originalJabatanPendiri ||
      file !== null;
    setIsChanged(changed);
    return changed;
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      setFileError("Ukuran file tidak boleh lebih dari 5 MB");
    } else {
      setFileError("");
      setFile(selectedFile);
    }
    // Panggil hasChange setelah mengubah file
    hasChange();
  };

  const handleShow = (pendiri) => {
    setSelectedPendiri(pendiri || null);
    setNamaPendiri(pendiri.nama || "");
    setJabatanPendiri(pendiri.jabatan || "");
    setShow(true);
    // Reset isChanged saat membuka modal
    setIsChanged(false);
  };

  const handleClose = () => {
    setShow(false);
    setNamaPendiriError("");
    setJabatanPendiriError("");
    setFileError("");

    setNamaPendiri(originalNamaPendiri);
    setJabatanPendiri(originalJabatanPendiri);
    setFile(null);
    // Reset isChanged saat menutup modal
    setIsChanged(false);
  };

  useEffect(() => {
    getDataPendiri();
  }, []);

  const getDataPendiri = async () => {
    setLoading("");
    setError("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/pendiri`
      );
      setData(response.data.data);

      setNamaPendiri(response.data.data.nama || "");
      setJabatanPendiri(response.data.data.jabatan || "");

      setOriginalNamaPendiri(response.data.data.nama || "");
      setOriginalJabatanPendiri(response.data.data.jabatan || "");
    } catch (error) {
      setError(error.message);
      console.error(error.message);
    }
  };

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
    hasChange();
  };

  // handle save
  const handleSave = async () => {
    setLoading(true);
    setError("");

    if (!hasChange()) {
      setLoading(false);
      return;
    }
    if (!validateInput()) {
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("nama", namaPendiri);
      formData.append("jabatan", jabatanPendiri);
      if (file) {
        formData.append("gambar_pendiri_yayasan", file);
      }
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/pendiri/${selectedPendiri.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: response.data.messagem,
      });
      getDataPendiri();
      handleClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error.response.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="row">
        <h2 className="mb-4 card-title text-success fw-bold">
          Pendiri Yayasan
        </h2>
        {data.map((item) => (
          <div className="col-lg-3 text-center" key={item.id}>
            <img
              style={{
                maxWidth: "80%",
              }}
              className="img-fluid rounded-3"
              src={item.url_resolusi_desktop}
              alt=""
            />
            <h4 className="text-dark font-weight-bold mt-2">{item.nama}</h4>
            <p className="text-muted">{item.jabatan}</p>
          </div>
        ))}
      </div>

      <div className="row mt-5">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-body">
              <h4 className="card-title">Kelola Pendiri Yayasan</h4>
              <h6 className="card-subtitle text-muted mb-2">
                Ubah Sesuai kebutuhan
              </h6>
              <div className="table-responsive">
                <table className="table table-hover table-bordered">
                  <thead className="bg-dark text-white">
                    <tr>
                      <th style={{ width: "10px" }}>No</th>
                      <th>Foto Pendiri</th>
                      <th>Nama Pendiri</th>
                      <th>Jabatan</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody className="border border-light">
                    {data.map((item, index) => (
                      <tr key={item.id}>
                        <td>{index + 1}</td>
                        <td>
                          <img
                            width="50"
                            height="50"
                            class="img-fluid rounded-3"
                            src={item.url_resolusi_desktop}
                            alt=""
                          />
                        </td>
                        <td className="align-middle">{item.nama}</td>
                        <td className="align-middle">{item.jabatan}</td>
                        <td className="align-middle">
                          <Button
                            variant="warning"
                            onClick={() => handleShow(item)}
                            className="btn btn-sm btn-primary me-2"
                          >
                            <i className="fas fa-edit me-1"></i>Edit
                          </Button>
                          {/* <Button
                            variant="danger"
                            className="btn btn-sm btn-danger"
                          >
                            <i className="fas fa-trash me-1"></i>Hapus
                          </Button> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* Start modal */}
              <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header>
                  <Modal.Title>Edit Pendiri Yayasan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    {selectedPendiri && (
                      <>
                        <div className="col-lg-12">
                          <img
                            style={{
                              maxWidth: "150px",
                              objectFit: "cover",
                            }}
                            src={selectedPendiri.url_resolusi_desktop}
                            alt="Selected Banner"
                            className="img-fluid rounded-3 mb-3"
                          />
                        </div>
                        <Alert variant="warning">
                          <span className="fw-bold">Perhatian:</span> Gambar
                          harus memiliki orientasi potrait dengan ukuran {""}
                          <span className="fw-bold">1016px * 1352px</span> dan
                          berformat JPEG, JPG, atau PNG dengan ukuran kurang
                          dari 5 MB.
                        </Alert>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <h4 className="fw-semibold">Foto Pendiri</h4>
                          </Form.Label>
                          <Form.Control
                            type="file"
                            className={`form-control ${
                              fileError ? "is-invalid" : ""
                            }`}
                            name="file"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                          {fileError && (
                            <Form.Control.Feedback type="invalid">
                              {fileError}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            {" "}
                            <h4 className="fw-semibold">Nama Pendiri</h4>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className={`form-control ${
                              namaPendiriError ? "is-invalid" : ""
                            }`}
                            name="nama"
                            placeholder="Masukkan nama pendiri"
                            value={namaPendiri}
                            onChange={(e) =>
                              handleInputChange(e, setNamaPendiri)
                            }
                          />
                          {namaPendiriError && (
                            <Form.Control.Feedback type="invalid">
                              {namaPendiriError}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            {" "}
                            <h4 className="fw-semibold">Jabatan</h4>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="jabatan"
                            placeholder="Masukkan jabatan pendiri"
                            className={`form-control ${
                              jabatanPendiriError ? "is-invalid" : ""
                            }`}
                            value={jabatanPendiri}
                            onChange={(e) =>
                              handleInputChange(e, setJabatanPendiri)
                            }
                          />
                          {jabatanPendiriError && (
                            <Form.Control.Feedback type="invalid">
                              {jabatanPendiriError}
                            </Form.Control.Feedback>
                          )}
                        </Form.Group>
                      </>
                    )}
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Batal
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleSave}
                    disabled={!isChanged || loading}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                        Menyimpan
                      </>
                    ) : (
                      "Simpan"
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* End Modal */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendiriYayasan;
