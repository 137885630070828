import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import $ from 'jquery';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-bs5';
import Swal from 'sweetalert2';

const EkstrakurikulerLembaga = () => {
  const [data, setData] = useState([]);
  const [idEkstrakurikuler, setIdEkstrakurikuler] = useState('');
  const [formData, setFormData] = useState({
    id_lembaga: '',
    nama: '',
    deskripsi: '',
    gambar_ekstrakurikuler: null,
    url_gambar_ekstrakurikuler_preview: null,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchLembaga, setSearchLembaga] = useState(null);
  const [lembagaOptions, setLembagaOptions] = useState([]);

  const tableRef = useRef();

  const handleChange = (e) => {
    if (e.target.name === 'gambar_ekstrakurikuler') {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleShowModal = () => setShow(true);
  const handleCloseModal = () => {
    setShow(false);
    setIdEkstrakurikuler('');

    setFormData({
      id_lembaga: '',
      nama: '',
      deskripsi: '',
      gambar_ekstrakurikuler: null,
      url_gambar_ekstrakurikuler_preview: null,
    });
  };

  const handleSave = async () => {
    setLoading(true);
    setError('');
    try {
      const formDataObj = new FormData();
      for (const key in formData) {
        formDataObj.append(key, formData[key]);
      }

      if (idEkstrakurikuler) {
        await axios.put(`${process.env.REACT_APP_BASE_URL}/ekskul/${idEkstrakurikuler}`, formDataObj, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        Swal.fire({
          icon: 'success',
          title: 'Data Berhasil Diubah',
          text: 'Data ekstrakurikuler berhasil diubah',
        });
      } else {
        await axios.post(`${process.env.REACT_APP_BASE_URL}/ekskul`, formDataObj, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        Swal.fire({
          icon: 'success',
          title: 'Data Berhasil Ditambahkan',
          text: 'Data ekstrakurikuler berhasil ditambahkan',
        });
      }

      handleCloseModal();
      getEktrakurikuler();
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: `Terjadi kesalahan: ${error.message}`,
      });
      console.error('Error saving ekstrakurikuler:', error);
    }
  };

  const handleEdit = (item) => {
    setIdEkstrakurikuler(item.id);
    setFormData({
      id_lembaga: item.id_lembaga,
      nama: item.nama,
      deskripsi: item.deskripsi,
      gambar_ekstrakurikuler: null,
      url_gambar_ekstrakurikuler_preview: item.url_gambar_ekstrakurikuler,
    });
    handleShowModal();
  };

  const fetchLembagaOptions = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/lembaga`);
      const options = response.data.data.map((lembaga) => ({
        value: lembaga.id,
        label: lembaga.nama_lembaga,
      }));
      setLembagaOptions(options);
    } catch (error) {
      console.error('Error fetching lembaga options:', error);
    }
  }, []);

  const getEktrakurikuler = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/ekskul`, {
        params: {
          page: currentPage,
          limit: 10,
          searchLembaga: searchLembaga ? searchLembaga.value : '',
        },
      });

      setData(response.data.data);
      setTotalItems(response.data.total);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  }, [currentPage, searchLembaga]);

  useEffect(() => {
    fetchLembagaOptions();
    getEktrakurikuler();
  }, [fetchLembagaOptions, getEktrakurikuler]);

  useEffect(() => {
    if (!show) {
      getEktrakurikuler();
    }
  }, [show, getEktrakurikuler]);

  useEffect(() => {
    initializeDataTable();
  }, [data]);

  const initializeDataTable = () => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable().clear().destroy();
    }

    $(tableRef.current).DataTable({
      resvonsive: true,
      data: data,
      columns: [
        {
          title: 'No',
          data: null,
          render: (data, type, row, meta) => meta.row + 1,
        },
        {
          title: 'Nama Lembaga',
          data: 'nama_lembaga',
        },
        {
          title: 'Nama Ekstrakurikuler',
          data: 'nama',
        },
        {
          title: 'Deskripsi',
          data: 'deskripsi',
        },
        {
          title: 'Foto Ekstrakurikuler',
          data: 'url_gambar_ekstrakurikuler',
          render: (data) => `<img class="img-fluid rounded mx-auto d-block" src="${data}" alt="Foto Ekstrakurikuler" style="width: 50%;"/>`,
        },
        {
          title: 'Aksi',
          data: null,
          render: function (data, type, row) {
            return `
          <div class="btn-group" role="group" aria-label="Aksi">
            <button class="btn btn-primary btn-sm me-2 edit-btn" data-id="${row.id}">
              <i class="fas fa-edit"></i> Edit
            </button>
            <button class="btn btn-danger btn-sm delete-btn" data-id="${row.id}">
              <i class="fas fa-trash"></i> Hapus
            </button>
          </div>
        `;
          },
        },
      ],
    });

    $(tableRef.current).on('click', '.edit-btn', function () {
      const id = $(this).data('id');
      const item = data.find((d) => d.id === id);
      if (item) {
        handleEdit(item);
      }
    });

    $(tableRef.current).on('click', '.delete-btn', function () {
      const id = $(this).data('id');
      handleDelete(id);
    });
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: 'Apakah anda yakin?',
        text: 'Apakah anda yakin akan menghapus dewan guru ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Batal',
        confirmButtonText: 'Ya, Hapus!',
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/ekskul/${id}`);
        Swal.fire({
          icon: 'success',
          title: 'Data Berhasil Dihapus',
          text: 'Data ekstrakurikuler berhasil dihapus',
        });
        getEktrakurikuler();
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: `Terjadi kesalahan: ${error.message}`,
      });
      console.error('Error deleting ekstrakurikuler:', error);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h2 className="text-success fw-bold">Kelola Ekstrakurikuler Lembaga</h2>
              <h6 className="text-muted">Berikut daftar ektrakurikuler yang ada di lembaga</h6>
              <Button variant="primary" className="mb-2" onClick={handleShowModal}>
                <i className="fas fa-plus me-2"></i>Tambah Ekstrakurikuler
              </Button>
              <div className="table-responsive">
                <table ref={tableRef} id="ekstrakurikulerTable" className="table table-striped">
                  <thead className="bg-dark text-white text-left">
                    <tr>
                      <th>No</th>
                      <th>Nama Lembaga</th>
                      <th>Nama Kegiatan</th>
                      <th>Deskripsi</th>
                      <th>Foto Kegiatan</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody className="text-left">{/* DataTables will automatically fill this */}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{idEkstrakurikuler ? 'Edit Ekstrakurikuler' : 'Tambah Ekstrakurikuler'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formLembaga" className="mb-3">
              <Form.Label>
                <h4 className="fw-semibold">Pilih Lembaga</h4>
              </Form.Label>
              <Select
                value={lembagaOptions.find((option) => option.value === formData.id_lembaga)}
                options={lembagaOptions}
                onChange={(selectedOption) =>
                  setFormData({
                    ...formData,
                    id_lembaga: selectedOption.value,
                  })
                }
              />
            </Form.Group>
            <Form.Group controlId="formNama" className="mb-3">
              <Form.Label>
                {' '}
                <h4 className="fw-semibold">Nama Ektrakurikuler</h4>
              </Form.Label>
              <Form.Control type="text" name="nama" value={formData.nama} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formDeskripsi" className="mb-3">
              <Form.Label>
                {' '}
                <h4 className="fw-semibold">Deskripsi</h4>
              </Form.Label>
              <Form.Control as="textarea" name="deskripsi" value={formData.deskripsi} onChange={handleChange} />
            </Form.Group>
            {formData.url_gambar_ekstrakurikuler_preview ? (
              <Form.Group controlId="logoLembagaPreview">
                <img src={formData.url_gambar_ekstrakurikuler_preview} alt="Foto Dewan Guru" className="img-fluid mb-3 mt-3" style={{ maxWidth: '150px' }} />
              </Form.Group>
            ) : (
              ''
            )}
            <Form.Group controlId="formGambar" className="mb-3">
              <Form.Label>
                {' '}
                <h4 className="fw-semibold"> {idEkstrakurikuler ? 'Ubah Ekstrakurikuler' : 'Gambar Ekstrakurikuler'}</h4>
              </Form.Label>
              <Form.Control type="file" name="gambar_ekstrakurikuler" onChange={handleChange} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Batal
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {loading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                Menyimpan
              </>
            ) : (
              'Simpan'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EkstrakurikulerLembaga;
