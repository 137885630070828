import React from 'react';
import { Button } from 'react-bootstrap';

const TitleSection = ({ title, subtitle, onClick, text }) => {
  return (
    <div className="card shadow mb-4">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="card-title font-weight-bold text-success fw-bold">{title}</h2>
            <h6 className="card-subtitle text-muted">{subtitle}</h6>
            <Button className="btn btn-info btn-md" onClick={onClick}>
              <i className="fas fa-edit me-1"></i> Ubah tampilan {text}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleSection;
