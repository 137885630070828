/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import $ from "jquery";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5";
import Swal from "sweetalert2";
import { render } from "@testing-library/react";
import { parseISO, format, set } from "date-fns";

const AgendaYayasan = () => {
  const [agenda, setAgenda] = useState([]);
  const [idAgenda, setIdAgenda] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);

  const [namaAgendaError, setNamaAgendaError] = useState("");
  const [tanggalMulaiError, setTanggalMulaiError] = useState("");
  const [tanggalSelesaiError, setTanggalSelesaiError] = useState("");

  const validateInput = () => {
    let isValid = true;
    setNamaAgendaError("");
    setTanggalMulaiError("");
    setTanggalSelesaiError("");

    if (formData.nama_agenda.trim() === "") {
      setNamaAgendaError("Nama agenda tidak boleh kosong.");
      isValid = false;
    }

    if (formData.tanggal_mulai.trim() === "") {
      setTanggalMulaiError("Tanggal mulai tidak boleh kosong.");
      isValid = false;
    }

    if (formData.tanggal_selesai < formData.tanggal_mulai) {
      setTanggalSelesaiError(
        "Tanggal selesai tidak boleh lebih kecil dari tanggal mulai."
      );
      isValid = false;
    }

    return isValid;
  };

  const [formData, setFormData] = useState({
    nama_agenda: "",
    tanggal_mulai: "",
    tanggal_selesai: "",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const tableRef = useRef();

  // Fetch data agenda
  const getAgenda = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/agendas`,
        {
          params: {
            page: currentPage,
            limit: 10,
          },
        }
      );

      const validAgenda = response.data.data.map((item) => ({
        ...item,
        tanggal_mulai: formatDateForInput(item.tanggal_mulai),
        tanggal_selesai: item.tanggal_selesai
          ? formatDateForInput(item.tanggal_selesai)
          : "",
      }));

      setAgenda(validAgenda);
      setTotalItems(response.data.total);
    } catch (error) {
      setError(error.message);
    }
    setLoading(false);
  }, [currentPage]);

  useEffect(() => {
    getAgenda();
  }, [getAgenda]);

  useEffect(() => {
    if (!show) {
      getAgenda();
    }
  }, [show, getAgenda]);

  useEffect(() => {
    initializeDatatable();
  }, [agenda]);

  const initializeDatatable = () => {
    if ($.fn.DataTable.isDataTable(tableRef.current)) {
      $(tableRef.current).DataTable().clear().destroy();
    }

    $(tableRef.current).DataTable({
      data: agenda,
      columns: [
        {
          title: "No",
          data: null,
          className: "text-center align-middle", // Hanya kolom "No" yang menggunakan text-center
          render: (data, type, row, meta) => meta.row + 1,
          width: "5%",
        },
        {
          title: "Agenda",
          data: "nama_agenda",
          className: "align-middle", // Teks tetap di kiri dengan align-middle
        },
        {
          title: "Tanggal Mulai",
          data: "tanggal_mulai",
          className: "align-middle", // Teks tetap di kiri dengan align-middle
          render: (data) => formatDateForDisplay(data),
        },
        {
          title: "Tanggal Selesai",
          data: "tanggal_selesai",
          className: "align-middle", // Teks tetap di kiri dengan align-middle
          render: (data) => formatDateForDisplay(data) || "-",
        },
        {
          title: "Aksi",
          data: null,
          className: "align-middle", // Teks tetap di kiri dengan align-middle
          render: (data, type, row) => {
            return `
        <div class="btn-group" role="group">
          <button class="btn btn-primary btn-sm me-2 edit-btn" data-id="${row.id}">
            <i class="fas fa-edit"></i> Edit
          </button>
          <button class="btn btn-danger btn-sm delete-btn" data-id="${row.id}">
            <i class="fas fa-trash"></i> Hapus
          </button>
        </div>
      `;
          },
          width: "15%",
        },
      ],
    });

    $(tableRef.current).on("click", ".edit-btn", function () {
      const id = $(this).data("id");
      const item = agenda.find((item) => item.id === id);
      if (item) {
        handleEdit(item);
      }
    });

    $(tableRef.current).on("click", ".delete-btn", function () {
      const id = $(this).data("id");
      handleDelete(id);
    });
  };

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setNamaAgendaError("");
    setTanggalMulaiError("");
    setTanggalSelesaiError("");

    setIdAgenda("");
    setFormData({
      nama_agenda: "",
      tanggal_mulai: "",
      tanggal_selesai: "",
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    setLoading(true);
    if (!validateInput()) {
      setLoading(false);
      return;
    }
    try {
      const dataToSend = {
        ...formData,
        tanggal_mulai: formatDateForServer(formData.tanggal_mulai),
        tanggal_selesai: formatDateForServer(formData.tanggal_selesai),
      };

      if (!dataToSend.nama_agenda) {
        throw new Error("Agenda harus diisi");
      }

      if (
        !dataToSend.tanggal_mulai ||
        isNaN(new Date(dataToSend.tanggal_mulai).getTime())
      ) {
        throw new Error("Tanggal mulai tidak valid");
      }

      if (
        dataToSend.tanggal_selesai &&
        isNaN(new Date(dataToSend.tanggal_selesai).getTime())
      ) {
        throw new Error("Tanggal selesai tidak valid");
      }

      if (idAgenda) {
        await axios.put(
          `${process.env.REACT_APP_BASE_URL}/agendas/${idAgenda}`,
          dataToSend
        );
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Agenda berhasil diubah",
        });
      } else {
        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/agendas`,
          dataToSend
        );
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Agenda berhasil ditambahkan",
        });
      }

      handleClose();
      getAgenda();
    } catch (error) {
      Swal.fire({ icon: "error", title: "Gagal", text: error.message });
      console.error("Terjadi kesalahan saat menyimpan data:", error);
    }
  };

  const handleEdit = (item) => {
    setIdAgenda(item.id);
    setFormData({
      nama_agenda: item.nama_agenda,
      tanggal_mulai: formatDateForInput(item.tanggal_mulai),
      tanggal_selesai: item.tanggal_selesai
        ? formatDateForInput(item.tanggal_selesai)
        : "",
    });
    handleShow();
  };

  const handleDelete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Apakah anda yakin?",
        text: "Apakah anda yakin akan menghapus agenda ini?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
      });

      if (result.isConfirmed) {
        await axios.delete(`${process.env.REACT_APP_BASE_URL}/agendas/${id}`);
        Swal.fire({
          icon: "success",
          title: "Terhapus",
          text: "Agenda berhasil dihapus",
        });
        getAgenda();
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Terjadi Kesalahan: ${error.message}`,
      });
    }
    setLoading(false);
  };

  const formatDateForInput = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }
    return date.toISOString().split("T")[0];
  };

  const formatDateForDisplay = (dateString) => {
    const date = parseISO(dateString);
    return isNaN(date.getTime()) ? "" : format(date, "dd MMMM yyyy");
  };

  const formatDateForServer = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "";
    }
    return date.toISOString();
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card shadow-sm">
              <div className="card-body">
                <h2 className="text-success fw-bold mb-3">
                  Kelola Agenda Yayasan
                </h2>
                <h6 className="text-muted mb-4">
                  Berikut daftar agenda yayasan yang telah ditambahkan
                </h6>
                <Button variant="primary" className="mb-4" onClick={handleShow}>
                  <i className="fas fa-plus me-2"></i> Tambah Agenda
                </Button>
                <div className="table-responsive">
                  <div>
                    <table
                      ref={tableRef}
                      id="agendaTable"
                      className="table table-striped table-bordered w-100"
                    >
                      <thead className="bg-dark text-white">
                        <tr>
                          <th>No</th>
                          <th>Agenda</th>
                          <th>Tanggal Mulai</th>
                          <th>Tanggal Selesai</th>
                          <th>Aksi</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* DataTables will automatically fill this */}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {idAgenda ? "Edit Agenda" : "Tambah Agenda"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                {" "}
                <h5 className="fw-semibold">Nama Agenda</h5>
              </Form.Label>
              <Form.Control
                type="text"
                name="nama_agenda"
                className={`form-control ${
                  namaAgendaError ? "is-invalid" : ""
                }`}
                value={formData.nama_agenda}
                onChange={handleChange}
                placeholder="Masukkan nama agenda"
              />
              {namaAgendaError && (
                <Form.Control.Feedback type="invalid">
                  {namaAgendaError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                {" "}
                <h5 className="fw-semibold">Tanggal Mulai</h5>
              </Form.Label>
              <Form.Control
                type="date"
                className={`form-control ${
                  tanggalMulaiError ? "is-invalid" : ""
                }`}
                name="tanggal_mulai"
                value={formData.tanggal_mulai}
                onChange={handleChange}
              />
              {tanggalMulaiError && (
                <Form.Control.Feedback type="invalid">
                  {tanggalMulaiError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                {" "}
                <h5 className="fw-semibold">Tanggal Selesai</h5>
              </Form.Label>
              <Form.Control
                type="date"
                className={`form-control ${
                  tanggalSelesaiError ? "is-invalid" : ""
                }`}
                name="tanggal_selesai"
                value={formData.tanggal_selesai}
                onChange={handleChange}
              />
              {tanggalSelesaiError && (
                <Form.Control.Feedback type="invalid">
                  {tanggalSelesaiError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Tutup
          </Button>
          <Button variant="primary" onClick={handleSave}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Menyimpan
              </>
            ) : (
              "Simpan"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AgendaYayasan;
