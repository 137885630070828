import React from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const nama = localStorage.getItem("nama");
  const navigate = useNavigate();

  const imageProfile =
    "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y&s=200";

  const logout = async () => {
    try {
      localStorage.clear("token");
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <header className="topbar" data-navbarbg="skin6">
        <nav className="navbar top-navbar navbar-expand-lg">
          <div className="navbar-header" data-logobg="skin6">
            {/* This is for the sidebar toggle which is visible on mobile only */}
            <a
              className="nav-toggler waves-effect waves-light d-block d-lg-none"
              href="#nothing"
            >
              <i className="ti-menu ti-close" />
            </a>
            {/* ============================================================== */}
            {/* Logo */}
            {/* ============================================================== */}
            <div className="navbar-brand">
              {/* Logo icon */}
              <a href="/dashboard">
                <img
                  src="../assets/images/logo_website.svg"
                  className="img-fluid"
                  alt=""
                />
              </a>
            </div>
            {/* ============================================================== */}
            {/* End Logo */}
            {/* ============================================================== */}
            {/* ============================================================== */}
            {/* Toggle which is visible on mobile only */}
            {/* ============================================================== */}
            <button
              className="topbartoggler d-block d-lg-none border-0 bg-transparent  waves-effect waves-light"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <i className="ti-more" />
            </button>
          </div>
          <div className="navbar-collapse collapse" id="navbarSupportedContent">
            <ul className="navbar-nav float-left me-auto ms-3 ps-1"></ul>
            <ul className="navbar-nav float-end">
              <li className="nav-item d-none d-md-block">
                <a className="nav-link" href="#nothing">
                  <form>
                    <div className="customize-input">
                      <input
                        className="form-control custom-shadow custom-radius border-0 bg-white"
                        type="search"
                        placeholder="Cari"
                        aria-label="Search"
                      />
                      <i className="form-control-icon" data-feather="search" />
                    </div>
                  </form>
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#nothing"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    src={imageProfile}
                    alt="user"
                    className="rounded-circle"
                    width={40}
                  />
                  <span className="ms-2 d-none d-lg-inline-block">
                    <span>Hello,</span>{" "}
                    <span className="text-dark">{nama}</span>{" "}
                    <i data-feather="chevron-down" className="svg-icon" />
                  </span>
                </a>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-right user-dd animated flipInY">
                  <Button className="dropdown-item" onClick={logout}>
                    <i data-feather="power" className="svg-icon me-2 ms-1" />
                    Logout
                  </Button>
                </div>
              </li>
              {/* ============================================================== */}
              {/* User profile and search */}
              {/* ============================================================== */}
            </ul>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
