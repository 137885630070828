import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

const PrivateRoute = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const navigate = useNavigate();

  // Fungsi untuk memeriksa apakah token masih valid
  const isTokenValid = () => {
    const token = localStorage.getItem("token");
    const exp = localStorage.getItem("exp");

    if (!token || !exp) return false;

    const current = new Date().getTime();
    const expired = new Date(parseInt(exp) * 1000).getTime();
    return current < expired;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (localStorage.getItem("token")) {
        if (!isTokenValid()) {
          setIsAuthenticated(false);
          setShowModal(true);
          localStorage.clear();
        }
      } else {
        setIsAuthenticated(false);
      }
    }, 1000); // Check every second

    return () => clearInterval(interval);
  }, []);

  const handleClose = () => {
    setShowModal(false);
    navigate("/", { replace: true }); // Arahkan ke halaman login
  };

  if (showModal) {
    return (
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Sesi Anda Telah Habis</Modal.Title>
        </Modal.Header>
        <Modal.Body>Silahkan login kembali.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  // Jika tidak ada token, arahkan ke halaman login
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  // Jika token valid, render children
  return children;
};

export default PrivateRoute;
