import React, { useState, useEffect } from "react";
import {
  Modal,
  Tabs,
  Tab,
  Button,
  Form,
  Spinner,
  Alert,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import formatTextToList from "../../../../utils/Fungsi/FormatTextToList";
import TitleSection from "../../../../components/TitleSection";

const MaknaLogoLembaga = () => {
  const [dataMaknaLogo, setDataMaknaLogo] = useState([]);
  const [idLembaga, setIdLembaga] = useState(
    localStorage.getItem("activeTab") || null
  );
  const [maknaByIdLembaga, setMaknaByIdLembaga] = useState("");
  const [makna_bentuk, setMaknaBentuk] = useState("");
  const [makna_warna, setMaknaWarna] = useState("");
  const [idMakna, setIdMakna] = useState("");

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [maknaWarnaError, setMaknaWarnaError] = useState("");
  const [maknaBentukError, setMaknaBentukError] = useState("");

  const validateInput = () => {
    let isValid = true;
    setMaknaWarnaError("");
    setMaknaBentukError("");

    if (makna_warna.trim() === "") {
      setMaknaWarnaError("Makna warna tidak boleh kosong.");
      isValid = false;
    }

    if (makna_bentuk.trim() === "") {
      setMaknaBentukError("Makna Bentuk tidak boleh kosong");
      isValid = false;
    }

    return isValid;
  };

  // State untuk menyimpan tab yang aktif
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || null
  );
  const handleShowModal = () => {
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  useEffect(() => {
    getMaknaLogo();
  }, []);

  const getMaknaLogo = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/filosofi-lambang-lembaga`
      );
      setDataMaknaLogo(response.data.data);
      if (response.data.data.length > 0 && !idLembaga) {
        setIdLembaga(response.data.data[0].id);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (idLembaga) {
      getMaknaLogoByIdLembaga(idLembaga);
      localStorage.setItem("activeTab", idLembaga);
      setActiveTab(idLembaga);
    }
  }, [idLembaga]);

  const getMaknaLogoByIdLembaga = async (id) => {
    setError("");
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/filosofi-lambang-lembaga/lembaga/${idLembaga}`
      );
      setMaknaByIdLembaga(response.data.data);
      setMaknaWarna(response.data.data.makna_warna);
      setMaknaBentuk(response.data.data.makna_bentuk);
      setIdMakna(response.data.data.id);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleSave = async (id) => {
    if (!validateInput()) {
      return;
    }
    setLoading(true);
    setError("");
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/filosofi-lambang-lembaga/${idMakna}`,
        {
          id_lembaga: idLembaga,
          makna_bentuk: makna_bentuk,
          makna_warna: makna_warna,
        }
      );

      Swal.fire({
        title: `Berhasil update sejarah ${maknaByIdLembaga.nama_lembaga}`,
        icon: "success",
        text: response.data.message,
      });

      await getMaknaLogo();
      await getMaknaLogoByIdLembaga(idLembaga);
      handleCloseModal();
      setError("");
      setLoading(false);
      setActiveTab(idLembaga);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
      console.log(error.response);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs
            activeKey={activeTab}
            onSelect={(k) => {
              setIdLembaga(k);
              setActiveTab(k);
            }}
            id="uncontrolled-tab-example"
          >
            {dataMaknaLogo.map((item, index) => (
              // eslint-disable-next-line jsx-a11y/no-access-key
              <Tab key={item.id} eventKey={item.id} title={item.nama_lembaga}>
                <TitleSection
                  title={`Kelola makna logo ${item.nama_lembaga}`}
                  subtitle={`Berikut tampilan dari makna logo ${item.nama_lembaga}`}
                  onClick={handleShowModal}
                  text="Makna Logo"
                />

                <div className="row">
                  <div className="col-lg-4">
                    <div className="card">
                      <div className="card-body d-flex align-items-center justify-content-center">
                        <img
                          style={{ maxWidth: "60%" }}
                          src={item.url_logo_lembaga}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="card-title fw-bold text-success ">
                          Makna Warna
                        </h3>
                        <ol className="px-3">
                          {formatTextToList(item.makna_warna, `\n`)}
                        </ol>
                        <h3 className="mt-4 card-title fw-bold text-success">
                          Makna Bentuk
                        </h3>
                        <ol className="px-3">
                          {formatTextToList(item.makna_bentuk, `\n`)}
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="text-dark">
              Ubah Makna Logo {maknaByIdLembaga.nama_lembaga}
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <Alert variant="info">
              <strong>Perhatian:</strong> Untuk makna warna dan makna bentuk,
              pisahkan setiap item dengan garis baru (Enter). Setiap item akan
              ditampilkan sebagai elemen daftar/list.
            </Alert>
          </div>
          {maknaByIdLembaga ? (
            <>
              <Form>
                <Form.Group className="mb-2">
                  <Form.Label>
                    <h4>Makna Warna</h4>
                  </Form.Label>
                  <Form.Control
                    as={"textarea"}
                    rows={5}
                    className={`form-control ${
                      maknaWarnaError ? "is-invalid" : ""
                    }`}
                    value={makna_warna}
                    onChange={(e) => setMaknaWarna(e.target.value)}
                    placeholder="Masukkan makna warna, pisahkan setiap item dengan garis baru (Enter)..."
                  />
                  {maknaWarnaError && (
                    <Form.Control.Feedback type="invalid">
                      {maknaWarnaError}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>
                    <h4>Makna Bentuk</h4>
                  </Form.Label>
                  <Form.Control
                    as={"textarea"}
                    rows={5}
                    className={`form-control ${
                      maknaBentukError ? "is-invalid" : ""
                    }`}
                    value={makna_bentuk}
                    onChange={(e) => setMaknaBentuk(e.target.value)}
                    placeholder="Masukkan makna bentuk, pisahkan setiap item dengan garis baru (Enter)..."
                  />
                  {maknaBentukError && (
                    <Form.Control.Feedback type="invalid">
                      {maknaBentukError}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Form>
            </>
          ) : (
            <p>loading ...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Batal
          </Button>
          <Button varian="success" onClick={handleSave} disabled={loading}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
                Menyimpan
              </>
            ) : (
              "Simpan"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MaknaLogoLembaga;
