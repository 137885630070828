import React from 'react';
import styles from './style.module.css';
const Thumbnile = () => {
  return (
    <div className={styles.thumbnile}>
      <picture className="">
        <img src="../assets/images/admin.jpg" alt="" style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }} />
      </picture>
    </div>
  );
};

export default Thumbnile;
