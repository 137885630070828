import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { AiOutlineLoading } from 'react-icons/ai';
import Swal from 'sweetalert2';
import formatTextToList from '../../../../utils/Fungsi/FormatTextToList';
import TitleSection from '../../../../components/TitleSection';
const VisiMisiYayasan = () => {
  const [data, setData] = useState('');
  const [visi, setVisi] = useState('');
  const [misi, setMisi] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [show, setShow] = useState(false);

  // original data

  const [originalVisi, setOriginalVisi] = useState('');
  const [originalMisi, setOriginalMisi] = useState('');

  const [visiError, setVisiError] = useState('');
  const [misiError, setMisiError] = useState('');

  const validateInput = () => {
    let isValid = true;
    setVisiError('');
    setMisiError('');

    if (!visi) {
      setVisiError('Visi tidak boleh kosong');
      return false;
    }

    if (!misi) {
      setMisiError('Misi tidak boleh kosong');
      return false;
    }

    return isValid;
  };

  const hasChange = () => {
    return visi !== originalVisi || misi !== originalMisi;
  };

  // jalankan fungsi get visiMisiYayasan
  useEffect(() => {
    getVisiMisiYayasan();
  }, []);

  // get data visi misi yayasan
  const getVisiMisiYayasan = async () => {
    setError('');
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/visi-misi-yayasan/1`);

      setData(response.data.data);
      setVisi(response.data.data.visi);
      setMisi(response.data.data.misi);

      setOriginalVisi(response.data.data.visi);
      setOriginalMisi(response.data.data.misi);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleShowModal = () => {
    setShow(true);
  };

  const handleClodeModal = () => {
    setShow(false);
    setError('');
    setVisi(originalVisi);
    setMisi(originalMisi);
  };

  const handleSave = async () => {
    setLoading(true);
    setError('');

    if (!hasChange()) {
      return;
    }

    if (!validateInput()) {
      return;
    }

    const formData = new FormData();
    formData.append('visi', visi);
    formData.append('misi', misi);
    try {
      await axios.put(`${process.env.REACT_APP_BASE_URL}/visi-misi-yayasan/1`, formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      getVisiMisiYayasan();
      setShow(false);
      Swal.fire({
        icon: 'success',
        title: 'Berhasil',
        text: 'Visi Misi berhasil diubah',
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <TitleSection title="Visi Misi Yayasan" subtitle="Berikut adalah tampilan dari visi misi yayasan" onClick={handleShowModal} text="Visi dan Misi" />
        </div>

        {/* start Modal */}
        <Modal show={show} size="lg" onHide={handleClodeModal}>
          <Modal.Header>
            <Modal.Title className="font-weight-bold text-dark">Ubah tampilan visi misi yayasan</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-4">
                <Form.Label>
                  <h4 className="fw-semibold">Visi</h4>
                </Form.Label>
                <Form.Control as="textarea" className={`form-control ${visiError ? 'is-invalid' : ''}`} rows={2} value={visi} onChange={(e) => setVisi(e.target.value)} placeholder="Masukan Visi" />
                {visiError && <Form.Control.Feedback type="invalid">{visiError}</Form.Control.Feedback>}
              </Form.Group>

              <Form.Group>
                <Form.Label>
                  <h4 className="fw-semibold">Misi</h4>
                </Form.Label>
                <Alert variant="warning">
                  {/* berikan informasi agar misi karena akan dijadikan daftar/list maka harus dipisahkan dengan enter */}
                  Perhatian: misi akan dijadikan daftar/list maka harap isi dipisahkan dengan baris baru, dengan cara menekan <b>ENTER</b>
                </Alert>
                <Form.Control as="textarea" className={`form-control ${misiError ? 'is-invalid' : ''}`} rows={9} value={misi} placeholder="Masukan Misi" onChange={(e) => setMisi(e.target.value)} />
                {misiError && <Form.Control.Feedback type="invalid">{misiError}</Form.Control.Feedback>}
              </Form.Group>
            </Form>
          </Modal.Body>
          {/* tampilan error */}
          {error && (
            <Alert variant="danger" className="m-4">
              {error}
            </Alert>
          )}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClodeModal}>
              Batal
            </Button>
            <Button variant="primary" onClick={handleSave} disabled={!hasChange() || loading}>
              {loading ? (
                <>
                  <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    // aria-hidden="true"
                    className="me-2"
                  />
                  Menyimpan
                </>
              ) : (
                'Simpan'
              )}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* End Modal */}
        <div className="col-lg-12">
          {loading ?? AiOutlineLoading}
          <div className="card shadow mb-4">
            <div className="card-body">
              <h4 className="card-title text-success fw-semibold mb-3">Visi</h4>
              <div className="d-flex align-items-center">
                <div className="border-start border-4 border-secondary" style={{ height: 'auto', marginLeft: '4px' }}>
                  <div className="px-2 text-secondary text-uppercase">"{data.visi}"</div>
                </div>
              </div>
              <div className="mt-4">
                <h4 className="card-title text-success fw-semibold mb-3">Misi</h4>
                <ol className="list px-3 text-secondary">{formatTextToList(data.misi)}</ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisiMisiYayasan;
