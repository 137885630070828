import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const isSpecialSegment = (segment) => {
    return ['yayasan', 'lembaga'].includes(segment.toLowerCase());
  };

  return (
    <div className="page-breadcrumb">
      <div className="row">
        <div className="col-7 align-self-center">
          <h4 className="page-title text-truncate text-dark font-weight-medium mb-1">{pathnames.length > 0 ? capitalizeFirstLetter(pathnames[pathnames.length - 1].replace(/-/g, ' ')) : 'Home'}</h4>
          <div className="d-flex align-items-center">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb m-0 p-0">
                <li className="breadcrumb-item">
                  <Link to="/dashboard" className="text-muted">
                    Dashboard
                  </Link>
                </li>
                {pathnames.map((name, index) => {
                  const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
                  const isLast = index === pathnames.length - 1;
                  const displayName = capitalizeFirstLetter(name.replace(/-/g, ' '));

                  if (isSpecialSegment(name)) {
                    return (
                      <li key={name} className="breadcrumb-item text-muted">
                        {displayName}
                      </li>
                    );
                  }

                  return isLast ? (
                    <li key={name} className="breadcrumb-item text-muted active" aria-current="page">
                      {displayName}
                    </li>
                  ) : (
                    <li key={name} className="breadcrumb-item">
                      <Link to={routeTo} className="text-muted">
                        {displayName}
                      </Link>
                    </li>
                  );
                })}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Breadcrumbs;
