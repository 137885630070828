import React from 'react';

const Footer = () => {
  return (
    <div>
      <footer className="footer text-center text-muted">
        All Rights Reserved by <a href="https://adminmart.com/">Awwaliyah Al-Asiyah</a>
      </footer>
    </div>
  );
};

export default Footer;
