import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Form, Button, Spinner, Alert } from "react-bootstrap";
import Swal from "sweetalert2";

const PengurusYayasan = () => {
  const [dataPengurus, setDataPengurus] = useState([]);
  const [namaPengurus, setNamaPengurus] = useState("");
  const [jabatanPengurus, setJabatanPengurus] = useState("");
  const [rolePengurus, setRolePengurus] = useState("Pengurus");
  const [statusFilter, setStatusFilter] = useState("");

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [editId, setEditId] = useState(null);

  const [originalNamaPengurus, setOriginalNamaPengurus] = useState("");
  const [originalJabatanPengurus, setOriginalJabatanPengurus] = useState("");
  const [originalRolePengurus, setOriginalRolePengurus] = useState("");

  const [namaPengurusError, setNamaPengurusError] = useState("");
  const [jabatanPengurusError, setJabatanPengurusError] = useState("");

  const [isChanged, setIsChanged] = useState(false);

  const validateInput = () => {
    let isValid = true;
    setNamaPengurusError("");
    setJabatanPengurusError("");

    if (namaPengurus.trim() === "") {
      setNamaPengurusError("Nama pengurus tidak boleh kosong");
      isValid = false;
    } else if (namaPengurus.length > 200) {
      setNamaPengurusError("Nama pengurus tidak boleh lebih dari 200 karakter");
      isValid = false;
    }

    if (jabatanPengurus.trim() === "") {
      setJabatanPengurusError("Jabatan pengurus tidak boleh kosong");
      isValid = false;
    } else if (jabatanPengurus.length > 200) {
      setJabatanPengurusError(
        "Jabatan pengurus tidak boleh lebih dari 200 karakter"
      );
      isValid = false;
    }
    return isValid;
  };

  const hasChange = () => {
    const changed =
      namaPengurus !== originalNamaPengurus ||
      jabatanPengurus !== originalJabatanPengurus ||
      rolePengurus !== originalRolePengurus;
    setIsChanged(changed);
    return changed;
  };

  useEffect(() => {
    getDataPengurus();
  }, []);

  const getDataPengurus = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/pengurus-yayasan`
      );
      setDataPengurus(response.data.data);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleShow = (id = null) => {
    setEditId(id);
    if (id) {
      const pengurus = dataPengurus.find((item) => item.id === id);
      setNamaPengurus(pengurus.nama_pengurus);
      setJabatanPengurus(pengurus.jabatan);
      setRolePengurus(pengurus.role);
      setOriginalNamaPengurus(pengurus.nama_pengurus);
      setOriginalJabatanPengurus(pengurus.jabatan);
      setOriginalRolePengurus(pengurus.role);
    } else {
      setNamaPengurus("");
      setJabatanPengurus("");
      setRolePengurus("Pengurus");
      setOriginalNamaPengurus("");
      setOriginalJabatanPengurus("");
      setOriginalRolePengurus("Pengurus");
    }
    setShow(true);
    setIsChanged(false);
  };

  const handleClose = () => {
    setShow(false);
    setError("");
    setNamaPengurus("");
    setJabatanPengurus("");
    setRolePengurus("Pengurus");
    setEditId(null);
    setNamaPengurusError("");
    setJabatanPengurusError("");
    setIsChanged(false);
  };

  const handleSave = async () => {
    if (!validateInput()) {
      return;
    }
    setLoading(true);
    setError("");
    const formData = new FormData();
    formData.append("nama_pengurus", namaPengurus);
    formData.append("jabatan", jabatanPengurus);
    formData.append("role", rolePengurus);

    try {
      const response = editId
        ? await axios.put(
            `${process.env.REACT_APP_BASE_URL}/pengurus-yayasan/${editId}`,
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
        : await axios.post(
            `${process.env.REACT_APP_BASE_URL}/pengurus-yayasan`,
            formData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: response.data.msg,
      });

      getDataPengurus();
      handleClose();
    } catch (error) {
      console.log(error.response.data.msg);
      setError(error.response.data.msg);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: "Yakin ingin menghapus?",
      text: "Data yang dihapus tidak bisa dikembalikan!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya, hapus!",
      cancelButtonText: "Batal",
    });

    if (result.isConfirmed) {
      setLoading(true);
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/pengurus-yayasan/${id}`
        );
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: response.data.msg,
        });
        getDataPengurus();
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleInputChange = (e, setter) => {
    setter(e.target.value);
    hasChange();
  };

  const filteredData = dataPengurus.filter((item) =>
    statusFilter ? item.role === statusFilter : true
  );

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h2 className="card-title fw-bold text-success font-weight-bold">
                Kelola Pengurus Yayasan
              </h2>
              <h6 className="card-subtitle">Berikut daftar pengurus yayasan</h6>
              <div className="d-flex justify-content-between">
                <Button
                  variant="primary"
                  className="mb-2"
                  onClick={() => handleShow()}
                >
                  <i className="fas fa-plus"></i> Tambah Pengurus
                </Button>
                <Form.Select
                  style={{ width: "200px" }}
                  className="mb-2"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option value="">Semua Status</option>
                  <option value="Pengurus">Pengurus</option>
                  <option value="Pengawas">Pengawas</option>
                  <option value="Pembina">Pembina</option>
                </Form.Select>
              </div>

              <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title className="text-dark">
                    {editId ? "Edit" : "Tambah"} Pengurus Yayasan
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <h4 className="fw-semibold">Nama</h4>
                      </Form.Label>
                      <Form.Control
                        as="input"
                        type="text"
                        placeholder="Masukkan nama pengurus"
                        value={namaPengurus}
                        onChange={(e) => handleInputChange(e, setNamaPengurus)}
                        isInvalid={!!namaPengurusError}
                      />
                      <Form.Control.Feedback type="invalid">
                        {namaPengurusError}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>
                        <h4 className="fw-semibold">Jabatan</h4>
                      </Form.Label>
                      <Form.Control
                        as="input"
                        type="text"
                        placeholder="Masukkan jabatan pengurus"
                        value={jabatanPengurus}
                        onChange={(e) =>
                          handleInputChange(e, setJabatanPengurus)
                        }
                        isInvalid={!!jabatanPengurusError}
                      />
                      <Form.Control.Feedback type="invalid">
                        {jabatanPengurusError}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <h4 className="fw-semibold">Peran</h4>
                      </Form.Label>
                      <Form.Select
                        value={rolePengurus}
                        onChange={(e) => handleInputChange(e, setRolePengurus)}
                      >
                        <option value="Pengurus">Pengurus</option>
                        <option value="Pengawas">Pengawas</option>
                        <option value="Pembina">Pembina</option>
                      </Form.Select>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Batal
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleSave}
                    disabled={!isChanged || loading}
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Simpan"
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>
              <div className="table-responsive">
                <table className="table">
                  <thead className="bg-dark">
                    <tr className="text-light">
                      <th style={{ width: "10px" }}>No</th>
                      <th>Status</th>
                      <th>Nama</th>
                      <th>Jabatan</th>
                      <th style={{ width: "200px" }}>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="5" className="text-center">
                          <Spinner animation="border" />
                        </td>
                      </tr>
                    ) : filteredData.length === 0 ? (
                      <tr>
                        <td colSpan="5" className="text-center">
                          <Alert variant="danger">Data tidak ditemukan</Alert>
                        </td>
                      </tr>
                    ) : (
                      filteredData.map((item, index) => (
                        <tr key={item.id}>
                          <td>{index + 1}</td>
                          <td>{item.role}</td>
                          <td>{item.nama_pengurus}</td>
                          <td>{item.jabatan}</td>
                          <td>
                            <Button
                              variant="warning"
                              size="sm"
                              className="me-2"
                              onClick={() => handleShow(item.id)}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="danger"
                              size="sm"
                              onClick={() => handleDelete(item.id)}
                            >
                              Hapus
                            </Button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PengurusYayasan;
