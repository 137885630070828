import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import CardAdmin from '../../../components/CardAdmin';
import Thumbnile from '../../../components/Thumbnile';

const Dashboard = () => {
  const [dataGuru, setDataGuru] = useState([]);
  const [dataKegiatan, setDataKegiatan] = useState([]);
  const [dataBerita, setDataBerita] = useState([]);
  const [currentGuru, setCurrentGuru] = useState(0);
  const [currentKegiatan, setCurrentKegiatan] = useState(0);
  const [currentBerita, setCurrentBerita] = useState(0);
  const nama = localStorage.getItem('nama');

  // get data guru

  const GetdataGuruBerjalan = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/dewan-guru-lembaga`);
    const data = response.data.data;
    let guruBerjalan = [];
    for (let i = 0; i < data.length; i++) {
      guruBerjalan.push(i + 1);
    }

    if (guruBerjalan.length === 0) return; // Tidak ada data untuk ditampilkan

    setDataGuru(guruBerjalan);

    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex < guruBerjalan.length) {
        setCurrentGuru(guruBerjalan[currentIndex]);
        currentIndex++;
      } else {
        clearInterval(intervalId); // Hentikan interval setelah semua data ditampilkan
      }
    }, 450);
  };

  // get data kegiatan
  const GetdataKegiatanBerjalan = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/ekskul`);
    const data = response.data.data;
    let kegiatanBerjalan = [];
    for (let i = 0; i < data.length; i++) {
      kegiatanBerjalan.push(i + 1);
    }

    if (kegiatanBerjalan.length === 0) return; // Tidak ada data untuk ditampilkan

    setDataKegiatan(kegiatanBerjalan);

    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex < kegiatanBerjalan.length) {
        setCurrentKegiatan(kegiatanBerjalan[currentIndex]);
        currentIndex++;
      } else {
        clearInterval(intervalId); // Hentikan interval setelah semua data ditampilkan
      }
    }, 400);
  };

  // get data berita berjalan
  const GetdataBeritaBerjalan = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/beritas`);
    const data = response.data.data;
    let dataBerita = [];
    for (let i = 0; i < data.length; i++) {
      dataBerita.push(i + 1);
    }

    if (dataBerita.length === 0) return; // Tidak ada data untuk ditampilkan

    setDataBerita(dataBerita);

    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex < dataBerita.length) {
        setCurrentBerita(dataBerita[currentIndex]);
        currentIndex++;
      } else {
        clearInterval(intervalId); // Hentikan interval setelah semua data ditampilkan
      }
    }, 300);

    // Bersihkan interval saat komponen di-unmount
    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    GetdataGuruBerjalan();
    GetdataKegiatanBerjalan();
    GetdataBeritaBerjalan();
  }, []);

  // data card untuk admin
  const dataCard = [
    {
      title: 'Guru / Pengurus',
      icon:
        'M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192l42.7 0c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0L21.3 320C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7l42.7 0C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3l-213.3 0zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352l117.3 0C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7l-330.7 0c-14.7 0-26.7-11.9-26.7-26.7z',
      count: currentGuru,
      viewBox: '0 0 700 512',
      captions: 'Total Guru / Pengurus Lembaga',
      bgColor: '#22C55E',
      padding: '0',
      w: 39,
      h: 39,
    },
    {
      title: 'Kegiatan',
      icon:
        'M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64L0 400c0 44.2 35.8 80 80 80l400 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L80 416c-8.8 0-16-7.2-16-16L64 64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z',
      count: currentKegiatan,
      viewBox: '0 0 512 512',
      captions: 'Total Kegiatan / Ekstrakurikuler',
      bgColor: '#5f76e8',
      padding: 'p-3',
      w: 30,
      h: 30,
    },
    {
      title: 'Berita & Artikel',
      icon:
        'M88.7 223.8L0 375.8 0 96C0 60.7 28.7 32 64 32l117.5 0c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7L416 96c35.3 0 64 28.7 64 64l0 32-336 0c-22.8 0-43.8 12.1-55.3 31.8zm27.6 16.1C122.1 230 132.6 224 144 224l400 0c11.5 0 22 6.1 27.7 16.1s5.7 22.2-.1 32.1l-112 192C453.9 474 443.4 480 432 480L32 480c-11.5 0-22-6.1-27.7-16.1s-5.7-22.2 .1-32.1l112-192z',
      count: currentBerita,
      viewBox: '0 0 560 512',
      captions: 'Total Berita & Artikel',
      bgColor: '#ff4f70',
      padding: 'p-3',
      w: 30,
      h: 30,
    },
  ];

  return (
    <>
      <div className="col-lg-12">
        <div className="dashboard">
          <Thumbnile />
          <div className=" mt-3 row">
            <div className=" col-lg-5 mb-4">
              <section className="position-relative rounded-4 " style={{ height: '13rem', background: 'linear-gradient(to top, #2f7f6c, #22c55e' }}>
                <div className=" position-relative w-100 ">
                  <div className="position-absolute top-0 start-0">
                    <img src="../assets/images/dekorasi/decore-left.png" alt="" />
                  </div>
                  <div className="position-absolute top-0 end-0">
                    <img src="../assets/images/dekorasi/decore-right.png" alt="" />
                  </div>
                </div>
                <div className="position-absolute top-50 start-50 translate-middle d-flex justify-content-center align-items-center flex-column gap-2 text-white">
                  <section className="d-flex justify-content-center  p-2 rounded-5" style={{ backgroundColor: '#22C55E' }}>
                    <i>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-award"
                        width="44"
                        height="44"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#ffffff"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M12 9m-6 0a6 6 0 1 0 12 0a6 6 0 1 0 -12 0" />
                        <path d="M12 15l3.4 5.89l1.598 -3.233l3.598 .232l-3.4 -5.889" />
                        <path d="M6.802 12l-3.4 5.89l3.598 -.233l1.598 3.232l3.4 -5.889" />
                      </svg>
                    </i>
                  </section>
                  <h3 className="mt-2 fs-4 fw-bold">Wellome {nama}</h3>
                  <span className="fs-6">Have fun your day :)</span>
                </div>
              </section>
            </div>
            <div className=" col-lg-7 ">
              <CardAdmin nama={nama} />
            </div>
          </div>
          <div className="row  gap-4 mb-4 mt-4 mt-lg-0">
            {dataCard.map((item, index) => (
              <div key={index} className="col-lg col-md-12 border shadow-sm rounded-4" style={{ backgroundColor: '#fff' }}>
                <div className="pt-4 px-3 row">
                  <section className="col-9 card-text p-0 m-0 d-flex   flex-column ">
                    <h3 className="mb-1 fs-4">{item.title}</h3>
                    <p className="fs-3 fw-bold  mb-2">{item.count}</p>
                    <p className="text-muted fs-6">{item.captions}</p>
                  </section>
                  <section className="col-3 d-flex justify-content-center   card-icon">
                    <div className="d-flex justify-content-center align-items-center" style={{ width: '3.5rem', height: '3.5rem' }}>
                      <span className={`rounded-circle ${item.padding}`} style={{ backgroundColor: item.bgColor, padding: '0.8rem' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox={item.viewBox} width={item.w} height={item.h} style={{ fill: '#f8fafc' }}>
                          <path d={item.icon} />
                        </svg>
                      </span>
                    </div>
                  </section>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
