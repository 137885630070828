import React, { useEffect, useState } from 'react';
import styles from './style.module.css';

const CardAdmin = ({ nama }) => {
  const [hari, setHari] = useState('');
  const [tanggal, setTanggal] = useState(null);
  const [date, setDate] = useState('');
  const [jam, setJam] = useState(null);

  const getDate = () => {
    const date = new Date();
    let dateFull = date.toLocaleDateString('in-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    setDate(dateFull);
  };

  const getHari = () => {
    const date = new Date();
    const day = date.getDay();

    switch (day) {
      case 0:
        setHari('Sunday');
        break;
      case 1:
        setHari('Monday');
        break;
      case 2:
        setHari('Tuesday');
        break;
      case 3:
        setHari('Wednesday');
        break;
      case 4:
        setHari('Thursday');
        break;
      case 5:
        setHari('Friday');
        break;
      case 6:
        setHari('Saturday');
        break;
      default:
        setHari('');
        break;
    }
  };

  const getTanggal = () => {
    const date = new Date();
    const day = date.getDate().toString().padStart(2, '0');
    setTanggal(day);
  };

  const getJam = () => {
    const date = new Date();
    const time = date.toLocaleTimeString('in-US', { hour: 'numeric', minute: 'numeric', second: 'numeric' });
    setJam(time);
  };

  useEffect(() => {
    getHari();
    getTanggal();
    getDate();
    const intervalId = setInterval(() => {
      getJam();
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  return (
    <section className="rounded-4 d-flex justify-content-between align-items-center card-admin shadow-sm bg-white" style={{ height: '13rem' }}>
      <section className="h-100 pt-4 p-3">
        <section className={styles.date}>
          <div className={styles.date_hari}>
            <h4 className="fs-6">{hari}</h4>
            <h2 className="text-success">{tanggal}</h2>
          </div>
          <div className={styles.date_pemisah}></div>
          <div className={styles.date_waktu}>
            <section className={styles.date_waktu_icon}>
              <i>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M128 0c17.7 0 32 14.3 32 32l0 32 128 0 0-32c0-17.7 14.3-32 32-32s32 14.3 32 32l0 32 48 0c26.5 0 48 21.5 48 48l0 48L0 160l0-48C0 85.5 21.5 64 48 64l48 0 0-32c0-17.7 14.3-32 32-32zM0 192l448 0 0 272c0 26.5-21.5 48-48 48L48 512c-26.5 0-48-21.5-48-48L0 192zm64 80l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm128 0l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM64 400l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zm112 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16z" />
                </svg>
              </i>
            </section>
            <section className={styles.date_waktu_text}>
              <h4 className="fs-6 text-success">{date}</h4>
              <span className="fs-6">{jam}</span>
            </section>
          </div>
        </section>
        <section className="text">
          <h3 className="text-success fs-4 fw-bold">Hallo {nama}</h3>
          <p className="fs-6 text-muted">Jangan ragu mengejar mimpi, hadapi tantangan dengan semangat! Sukses adalah milik mereka yang berjuang</p>
        </section>
      </section>
      <section className=" img w-100 d-flex justify-content-center align-items-center" style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }}>
        <img src="../assets/images/login.svg" alt="gambar" width={'100%'} style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px' }} />
      </section>
    </section>
  );
};

export default CardAdmin;
