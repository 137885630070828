import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Spinner, Alert } from 'react-bootstrap';
import Swal from 'sweetalert2';

const DataLembaga = () => {
  const [dataLembaga, setDataLembaga] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedLembaga, setSelectedLembaga] = useState(null);
  const [formData, setFormData] = useState({
    nama_lembaga: '',
    deskripsi: '',
    logo_lembaga: null,
    logo_lembaga_preview: null,
    gambar_hero_lembaga: null,
    gambar_hero_lembaga_preview: null,
  });

  const [namaLembagaError, setNamaLembagaError] = useState('');
  const [deskripsiError, setDeskripsiError] = useState('');
  const [logoLembagaError, setLogoLembagaError] = useState('');
  const [gambarHeroLembagaError, setGambarHeroLembagaError] = useState('');

  const validateInput = () => {
    let isValid = true;
    if (!formData.nama_lembaga.trim() === '') {
      setNamaLembagaError('Nama lembaga tidak boleh kosong');
      isValid = false;
    }

    if (!formData.deskripsi.trim() === '') {
      setDeskripsiError('Deskripsi tidak boleh kosong');
      isValid = false;
    }
    return isValid;
  };

  const [isSaving, setIsSaving] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    getDataLembaga();
  }, []);

  const getDataLembaga = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/lembaga`);
      setDataLembaga(response.data.data);
    } catch (error) {
      setError(error.message);
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (lembaga) => {
    setSelectedLembaga(lembaga);
    setFormData({
      nama_lembaga: lembaga.nama_lembaga,
      logo_lembaga: null,
      logo_lembaga_preview: lembaga.url_logo_lembaga,
      deskripsi: lembaga.deskripsi,
      gambar_hero_lembaga: null,
      gambar_hero_lembaga_preview: lembaga.url_resolusi_desktop,
    });
    setFormErrors({});
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLembaga(null);
    setNamaLembagaError('');
    setDeskripsiError('');
    setLogoLembagaError('');
    setGambarHeroLembagaError('');

    setFormData({
      nama_lembaga: '',
      logo_lembaga: null,
      logo_lembaga_preview: null,
      deskripsi: '',
      gambar_hero_lembaga: null,
      gambar_hero_lembaga_preview: null,
    });
    setFormErrors({});
  };

  const handleChange = (e) => {
    if (e.target.name === 'logo_lembaga' || e.target.name === 'gambar_hero_lembaga') {
      setFormData({ ...formData, [e.target.name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    setFormErrors({ ...formErrors, [e.target.name]: '' });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.nama_lembaga.trim()) {
      errors.nama_lembaga = 'Nama lembaga tidak boleh kosong';
    }
    if (!selectedLembaga.logo_lembaga && !formData.logo_lembaga) {
      errors.logo_lembaga = 'Logo lembaga harus diisi';
    }
    if (!selectedLembaga.gambar_hero_lembaga && !formData.gambar_hero_lembaga) {
      errors.gambar_hero_lembaga = 'Banner lembaga harus diisi';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    if (!validateInput()) return;

    setIsSaving(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('nama_lembaga', formData.nama_lembaga);
      formDataToSend.append('deskripsi', formData.deskripsi);
      if (formData.logo_lembaga) {
        formDataToSend.append('logo_lembaga', formData.logo_lembaga);
      }
      if (formData.gambar_hero_lembaga) {
        formDataToSend.append('gambar_hero_lembaga', formData.gambar_hero_lembaga);
      }

      await axios.patch(`${process.env.REACT_APP_BASE_URL}/lembaga/${selectedLembaga.id}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      handleCloseModal();
      getDataLembaga();
      Swal.fire({
        icon: 'success',
        title: 'Berhasil!',
        text: 'Data lembaga berhasil diupdate',
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Gagal!',
        text: `${error.response?.data?.message || ''}`,
      });
    } finally {
      setIsSaving(false);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="card shadow mb-4">
      <div className="card-body">
        <h2 className="fw-bold text-success">Kelola Data Lembaga</h2>
        <h6 className="card-subtitle text-muted mb-4">Berikut adalah data lembaga yang terdaftar di yayasan</h6>
        <div className="table-responsive">
          <table className="table table-hover table-bordered">
            <thead className="bg-dark text-white">
              <tr>
                <th style={{ width: '10px' }}>No</th>
                <th>Logo & Nama</th>
                <th>Deskripsi</th>
                <th>Banner Lembaga</th>
                <th>Aksi</th>
              </tr>
            </thead>
            <tbody className="border border-light">
              {dataLembaga.map((item, index) => (
                <tr key={item.id}>
                  <td className="align-middle text-center" style={{ width: '5%' }}>
                    {index + 1}
                  </td>
                  <td className="align-middle" style={{ width: '30%' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}
                    >
                      <img
                        style={{
                          width: '40px',
                          height: '40px',
                          borderRadius: '50%',
                        }}
                        src={item.url_logo_lembaga}
                        alt="logo lembaga"
                      />
                      <span>{item.nama_lembaga}</span>
                    </div>
                  </td>
                  <td
                    className="align-middle"
                    style={{
                      maxWidth: '250px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {item.deskripsi.length > 50 ? item.deskripsi.substring(0, 50) + '...' : item.deskripsi}
                  </td>
                  <td className="align-middle text-center" style={{ width: '20%' }}>
                    <img
                      style={{
                        maxWidth: '150px',
                        height: 'auto',
                        borderRadius: '5px',
                      }}
                      src={item.url_resolusi_desktop}
                      alt=""
                    />
                  </td>
                  <td className="align-middle text-center" style={{ width: '15%' }}>
                    <button
                      className="btn btn-sm btn-warning"
                      onClick={() => handleEdit(item)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <i className="fas fa-edit me-1"></i>Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal size="lg" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Lembaga</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>
                <h4 className="fw-semibold">Nama Lembaga</h4>
              </Form.Label>
              <Form.Control type="text" name="nama_lembaga" value={formData.nama_lembaga} onChange={handleChange} isInvalid={!!formErrors.nama_lembaga} />
              <Form.Control.Feedback type="invalid">{formErrors.nama_lembaga}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                {' '}
                <h4 className="fw-semibold">Deskripsi Lembaga</h4>
              </Form.Label>
              <Form.Control as="textarea" name="deskripsi" rows={3} value={formData.deskripsi} onChange={handleChange} />
            </Form.Group>

            {formData.logo_lembaga_preview ? (
              <Form.Group controlId="logoLembagaPreview">
                <img src={formData.logo_lembaga_preview} alt="Foto Dewan Guru" className="mb-3" style={{ maxWidth: '100px' }} />
              </Form.Group>
            ) : (
              ''
            )}
            <Alert variant="warning">
              <span className="fw-bold">Perhatian:</span> Logo sebaiknya menggunakan format <span className="fw-bold">.svg </span>
              dengan ukuran kurang dari 5 MB.
            </Alert>
            <Form.Group className="mb-3">
              <Form.Label>
                {' '}
                <h4 className="fw-semibold">Logo Lembaga</h4>
              </Form.Label>
              <Form.Control type="file" name="logo_lembaga" onChange={handleChange} accept=".jpg,.jpeg,.png,.svg" isInvalid={!!formErrors.logo_lembaga} />
              <Form.Control.Feedback type="invalid">{formErrors.logo_lembaga}</Form.Control.Feedback>
            </Form.Group>

            {formData.gambar_hero_lembaga_preview ? (
              <Form.Group controlId="logoLembagaPreview">
                <img src={formData.gambar_hero_lembaga_preview} alt="Foto Dewan Guru" className="img-fluid mb-3" style={{ maxWidth: '150px' }} />
              </Form.Group>
            ) : (
              ''
            )}

            <Form.Group className="mb-3">
              <Alert variant="warning">
                <span className="fw-bold">Perhatian:</span> Gambar harus memiliki orientasi <span className="fw-bold">Landscape</span> dengan resolusi<span className="fw-bold"> 1600px * 700px</span> format JPEG, JPG, atau PNG dengan ukuran
                kurang dari 5 MB.
              </Alert>
              <Form.Label>
                {' '}
                <h4 className="fw-semibold">Banner Lembaga</h4>
              </Form.Label>
              <Form.Control type="file" name="gambar_hero_lembaga" onChange={handleChange} accept=".jpg,.jpeg,.png,.svg" isInvalid={!!formErrors.gambar_hero_lembaga} />
              <Form.Control.Feedback type="invalid">{formErrors.gambar_hero_lembaga}</Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Batal
          </Button>
          <Button variant="primary" onClick={handleSubmit} disabled={isSaving}>
            {isSaving ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span className="ms-2">Menyimpan...</span>
              </>
            ) : (
              'Simpan'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DataLembaga;
