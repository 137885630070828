import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Modal,
  Button,
  Form,
  Spinner,
  Alert,
} from "react-bootstrap";
import Swal from "sweetalert2";
import formatTextToList from "../../../../utils/Fungsi/FormatTextToList";

const VisiMisiLembaga = () => {
  const [visiMisiLembaga, setVisiMisiLembaga] = useState([]);
  const [idLembaga, setIdLembaga] = useState(
    localStorage.getItem("activeTab") || null
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedLembaga, setSelectedLembaga] = useState(null);
  const [loading, setLoading] = useState(false);

  const [visiError, setVisiError] = useState("");
  const [misiError, setMisiError] = useState("");

  const validateInput = () => {
    let isValid = true;
    setVisiError("");
    setMisiError("");

    if (selectedLembaga.visi.trim() === "") {
      setVisiError("Visi lembaga tidak boleh kosong.");
      isValid = false;
    }

    if (selectedLembaga.misi.trim() === "") {
      setMisiError("Misi lembaga tidak boleh kosong.");
      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    getVisiMisiLembaga();
  }, []);

  const getVisiMisiLembaga = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/visi-misi-lembaga`
      );
      setVisiMisiLembaga(response.data.data);
      if (response.data.data.length > 0 && !idLembaga) {
        setIdLembaga(response.data.data[0].id);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleEdit = (lembaga) => {
    setSelectedLembaga({ ...lembaga });
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedLembaga(null);
  };

  const handleSave = async () => {
    if (!validateInput()) {
      return;
    }
    setLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/visi-misi-lembaga/${selectedLembaga.id}`,
        selectedLembaga
      );
      Swal.fire({
        icon: "success",
        title: "Berhasil!",
        text: "Data visi misi lembaga berhasil diperbarui.",
      });
      getVisiMisiLembaga();
      handleClose();
      console.log(response.data.message);
    } catch (error) {
      console.error(error.message);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Terjadi kesalahan saat memperbarui data.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setSelectedLembaga({ ...selectedLembaga, [e.target.name]: e.target.value });
  };

  const renderTabContent = (item) => (
    <>
      <div className="card mt-3">
        <div className="card-body">
          <h5 className="card-title">
            Kelola Visi Misi Lembaga {item.nama_lembaga}
          </h5>
          <h6>Berikut adalah tampilan dari visi misi lembaga</h6>
          <Button variant="primary" onClick={() => handleEdit(item)}>
            <i className="fas fa-edit"></i> Ubah Visi Misi {item.nama_lembaga}
          </Button>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card shadow mb-4">
            <div className="card-body">
              <h4 className="card-title  fw-bold">Visi</h4>
              <div className="d-flex align-items-center">
                <div
                  className="border-start border-4 border-success mt-2"
                  style={{ height: "auto", marginLeft: "4px" }}
                >
                  <div className="px-2 text-secondary text-dark text-uppercase">
                    "{item.visi}"
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <h4 className="card-title fw-bold mb-3">Misi</h4>
                <ol className="list px-3 text-secondary">
                  {formatTextToList(item.misi)}
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="row">
      <div className="col-lg-12">
        <Tabs activeKey={idLembaga || "1"} onSelect={(k) => setIdLembaga(k)}>
          {visiMisiLembaga.map((item) => (
            <Tab key={item.id} eventKey={item.id} title={item.nama_lembaga}>
              {renderTabContent(item)}
            </Tab>
          ))}
        </Tabs>
      </div>

      <Modal show={showModal} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Visi Misi Lembaga {selectedLembaga?.nama_lembaga || ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>
                <h4 className="fw-semibold">Visi</h4>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                className={`form-control ${visiError ? "is-invalid" : ""}`}
                name="visi"
                value={selectedLembaga?.visi || ""}
                onChange={handleInputChange}
                placeholder="Masukan visi lembaga"
              />
              {visiError && (
                <Form.Control.Feedback type="invalid">
                  {visiError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <h4 className="fw-semibold">Misi</h4>
              </Form.Label>
              <Alert variant="info">
                Perhatian: misi akan dijadikan daftar/list maka harap isi
                dipisahkan dengan baris baru, dengan cara menekan{" "}
                <b>CTRL + ENTER</b>
              </Alert>
              <Form.Control
                as="textarea"
                rows={5}
                name="misi"
                className={`form-control ${misiError ? "is-invalid" : ""}`}
                value={selectedLembaga?.misi || ""}
                onChange={handleInputChange}
                placeholder="Masukan misi lembaga"
              />
              {misiError && (
                <Form.Control.Feedback type="invalid">
                  {misiError}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Batal
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={loading}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Loading...</span>
              </>
            ) : (
              "Simpan"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VisiMisiLembaga;
