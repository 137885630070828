import axios from "axios";
import React, { useState, useEffect } from "react";
import { Tabs, Tab, Modal, Button, Form, ModalFooter } from "react-bootstrap";
import Swal from "sweetalert2";
import TitleSection from "../../../../components/TitleSection";

const ProfilLembaga = () => {
  const [profilLembaga, setProfilLembaga] = useState([]);
  const [profileByIdLembaga, setProfileByIdLembaga] = useState("");
  const [idProfil, setIdProfil] = useState("");
  const [npsn, setNpsn] = useState("");
  const [statusLembaga, setStatusLembaga] = useState("");
  const [akreditasi, setAkreditasi] = useState("");
  const [alamat, setAlamat] = useState("");
  const [noTelpon, setNoTelpon] = useState("");
  const [email, setEmail] = useState("");
  const [instagram, setInstagram] = useState("");
  const [facebook, setFacebook] = useState("");

  const [idLembaga, setIdLembaga] = useState(
    localStorage.getItem("activeTab") || null
  );

  const [activeTab, setActiveTabs] = useState(
    localStorage.getItem("activeTab") || null
  );

  // state error dan modal
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleShowModal = () => {
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  useEffect(() => {
    getProfilLembaga();
  }, []);

  const getProfilLembaga = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/profil-lembaga`
      );
      setProfilLembaga(response.data.data);
      if (response.data.data.length > 0 && !idLembaga) {
        setIdLembaga(response.data.data[0].id);
        setActiveTabs(response.data.data[0].id);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  useEffect(() => {
    if (idLembaga) {
      getProfilLembagaByIdLemabaga(idLembaga);
      localStorage.setItem("activeTab", idLembaga);
      setActiveTabs(idLembaga);
    }
  }, [idLembaga]);

  const getProfilLembagaByIdLemabaga = async (id) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/profil-lembaga/${idLembaga}`
      );
      setProfileByIdLembaga(response.data.data);
      setIdProfil(response.data.data.id);
      setNpsn(response.data.data.npsn);
      setStatusLembaga(response.data.data.status_lembaga);
      setAkreditasi(response.data.data.akreditasi);
      setAlamat(response.data.data.alamat);
      setNoTelpon(response.data.data.no_telpon);
      setEmail(response.data.data.email);
      setInstagram(response.data.data.instagram);
      setFacebook(response.data.data.facebook);
      setLoading(false);
    } catch (error) {
      console.log(error.response.data.message);
      setLoading(false);
    }
  };

  const handleSave = async (id) => {
    setLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/profil-lembaga/${idProfil}`,
        {
          id_lembaga: idLembaga,
          npsn: npsn,
          status_lembaga: statusLembaga,
          akreditasi: akreditasi,
          alamat: alamat,
          no_telpon: noTelpon,
          email: email,
          instagram: instagram,
          facebook: facebook,
        }
      );

      Swal.fire({
        icon: "success",
        title: "Berhasil",
        text: response.data.message,
      });

      await getProfilLembaga();
      await getProfilLembagaByIdLemabaga(idLembaga);
      setLoading(false);
      handleCloseModal();
      setActiveTabs(idLembaga);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Gagal",
        text: error.response.data.message,
      });
      console.log(error.response.data.message);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs
            activetab={activeTab}
            onSelect={(k) => {
              setIdLembaga(k);
              setActiveTabs(k);
            }}
            id="uncontroller-exmple-tab"
          >
            {profilLembaga.map((item) => (
              <Tab key={item.id} eventKey={item.id} title={item.nama_lembaga}>
                <div className="mt-3">
                  <TitleSection
                    title={`Profil Lembaga ${item.nama_lembaga}`}
                    subtitle={`Berikut adalah tampilan dari ${item.nama_lembaga}`}
                    onClick={handleShowModal}
                    text={"Profil Lembaga"}
                  />
                </div>

                <div className="row">
                  <div className="col-12-lg">
                    <div className="card">
                      <div className="card-body">
                        <div className="card-title">
                          <h3 className="fw-bold text-success">Profil Lembaga {item.nama_lembaga}</h3>
                        </div>
                        <table className="table table-bordered">
                          <tbody>
                            <tr key={item.id}>
                              <td className="col-3 font-weight-bold">
                                Nama Lembaga
                              </td>
                              <td className="col-1 text-center">:</td>
                              <td className="col-8">{item.nama_lembaga}</td>
                            </tr>
                            <tr>
                              <td className="font-weight-bold">NPSN</td>
                              <td className="text-center">:</td>
                              <td>{item.npsn}</td>
                            </tr>
                            <tr>
                              <td className="font-weight-bold">
                                Status Lembaga
                              </td>
                              <td className="text-center">:</td>
                              <td>{item.status_lembaga}</td>
                            </tr>
                            <tr>
                              <td className="font-weight-bold">Akreditasi</td>
                              <td className="text-center">:</td>
                              <td>{item.akreditasi}</td>
                            </tr>
                            <tr>
                              <td className="font-weight-bold">Alamat</td>
                              <td className="text-center">:</td>
                              <td>{item.alamat}</td>
                            </tr>
                            <tr>
                              <td className="font-weight-bold">Telepon</td>
                              <td className="text-center">:</td>
                              <td>{item.no_telpon}</td>
                            </tr>
                            <tr>
                              <td className="font-weight-bold">Email</td>
                              <td className="text-center">:</td>
                              <td>{item.email}</td>
                            </tr>
                            <tr>
                              <td className="font-weight-bold">Instagram</td>
                              <td className="text-center">:</td>
                              <td>{item.instagram}</td>
                            </tr>
                            <tr>
                              <td className="font-weight-bold">Facebook</td>
                              <td className="text-center">:</td>
                              <td>{item.facebook}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="text-dark">
              Edit Profil Lembaga {profileByIdLembaga.nama_lembaga}
            </h3>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form>
            {loading ? (
              <div className="text-center">
                <i className="fas fa-spinner fa-spin"></i>
              </div>
            ) : (
              <>
                <Form.Group className="mb-2">
                  <Form.Label>
                    <h4 className="fw-semibold">NPSN</h4>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={npsn}
                    className="form-control"
                    onChange={(e) => setNpsn(e.target.value)}
                    placeholder="Masukkan NPSN..."
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>
                    <h4 className="fw-semibold">Status Lembaga</h4>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={statusLembaga}
                    onChange={(e) => setStatusLembaga(e.target.value)}
                    placeholder="Masukkan status lembaga..."
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>
                    <h4 className="fw-semibold">Akreditasi</h4>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={akreditasi}
                    onChange={(e) => setAkreditasi(e.target.value)}
                    placeholder="Masukkan akreditasi..."
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>
                    <h4 className="fw-semibold">Alamat</h4>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={alamat}
                    onChange={(e) => setAlamat(e.target.value)}
                    placeholder="Masukkan alamat..."
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>
                    <h4 className="fw-semibold">No Telepon</h4>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={noTelpon}
                    onChange={(e) => setNoTelpon(e.target.value)}
                    placeholder="Masukkan no. telpon..."
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>
                    <h4 className="fw-semibold">Email</h4>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Masukkan email..."
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>
                    <h4 className="fw-semibold">Instagram</h4>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                    placeholder="Masukkan akun instagram..."
                  />
                </Form.Group>
                <Form.Group className="mb-2">
                  <Form.Label>
                    <h4 className="fw-semibold">Facebook</h4>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                    placeholder="Masukkan akun instagram..."
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="success" onClick={() => handleSave(idLembaga)}>
            Simpan
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProfilLembaga;
