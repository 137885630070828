import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Tab, Button, Form, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import TitleSection from '../../../../components/TitleSection';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const SambutanLembaga = () => {
  // State untuk menyimpan data sambutan lembaga
  const [data, setData] = useState([]);
  const [idLembaga, setIdLembaga] = useState(localStorage.getItem('activeTab') || null);
  const [selectedSambutan, setSelectedSambutan] = useState(null);
  const [namakepsek, setNamaKepsek] = useState('');
  const [jabatanKepsek, setJabatanKepsek] = useState('');
  const [isiSambutan, setIsiSambutan] = useState('');
  const [fotoKepsek, setFotoKepsek] = useState(null);

  // State untuk loading dan error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [namaError, setNamaError] = useState('');
  const [jabatanError, setJabatanError] = useState('');
  const [isiSambutanError, setIsiSambutanError] = useState('');
  const [fotoKepsekError, setFotoKepsekError] = useState('');

  const validateInput = () => {
    let isValid = true;

    if (namakepsek.trim() === '') {
      setNamaError('Nama kepala sekolah/lembaga harus diisi');
      isValid = false;
    }

    if (jabatanKepsek.trim() === '') {
      setJabatanError('Jabatan kepala sekolah/lembaga harus diisi');
      isValid = false;
    }

    if (isiSambutan.trim() === '') {
      setIsiSambutanError('Isi sambutan kepala sekolah/lembaga harus diisi');
      isValid = false;
    }

    return isValid;
  };

  // State untuk handle Modal
  const [show, setShow] = useState(false);

  // State untuk menyimpan tab yang aktif
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || null);

  // Handle show modal
  const handleShowModal = (id) => {
    setIdLembaga(id);
    setShow(true);
  };

  // Handle close modal
  const handleCloseModal = () => {
    setShow(false);
    setNamaError('');
    setJabatanError('');
    setIsiSambutanError('');
    setFotoKepsekError('');
  };

  useEffect(() => {
    getSambutanLembaga();
  }, []);

  useEffect(() => {
    if (idLembaga) {
      getSambutanByIdLembaga(idLembaga);
      localStorage.setItem('activeTab', idLembaga);
      setActiveTab(idLembaga);
    }
  }, [idLembaga]);

  const getSambutanLembaga = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/pidato-lembaga`);
      setData(response.data.data);

      if (response.data.data.length > 0 && !idLembaga) {
        setIdLembaga(response.data.data[0].id);
      }
    } catch (error) {
      setError(error.message);
      console.error(error.message);
    }
  };

  const getSambutanByIdLembaga = async (id) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/pidato-lembaga/lembaga/${id}`);
      setSelectedSambutan(response.data.data);
      setNamaKepsek(response.data.data.nama_kepsek);
      setJabatanKepsek(response.data.data.jabatan);
      setIsiSambutan(response.data.data.isi_sambutan);

      if (response.data.data.url_foto_kepsek) {
        setFotoKepsek(response.data.data.url_foto_kepsek);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleFotoKepsek = (e) => {
    setFotoKepsek(e.target.files[0]);
  };

  const handleSave = async () => {
    if (!validateInput()) {
      return;
    }

    setLoading(true);
    setError('');
    const formData = new FormData();
    formData.append('id_lembaga', idLembaga);
    formData.append('nama_kepsek', namakepsek);
    formData.append('jabatan', jabatanKepsek);
    formData.append('isi_sambutan', isiSambutan);
    if (fotoKepsek instanceof File) {
      formData.append('foto_kepsek', fotoKepsek);
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/pidato-lembaga/${selectedSambutan.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      Swal.fire({
        title: `Berhasil Update Sambutan ${selectedSambutan.nama_lembaga}`,
        text: response.data.message,
        icon: 'success',
      });

      await getSambutanByIdLembaga(idLembaga);
      await getSambutanLembaga();
      setLoading(false);
      handleCloseModal();
      setError('');
      setActiveTab(idLembaga);
    } catch (error) {
      setLoading(false);
      setError(error.response.data.message);
      console.log(error.response.data.message);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-lg-12 mb-2">
          <Tabs
            activeKey={activeTab}
            onSelect={(k) => {
              setIdLembaga(k);
              setActiveTab(k);
            }}
            id="uncontrolled-tab-example"
          >
            {data.map((item) => (
              <Tab key={item.id} eventKey={item.id} title={item.lembaga.nama_lembaga}>
                <div className="col-lg-12 mt-3">
                  <TitleSection title={`Kelola Sambutan Lembaga ${item.lembaga.nama_lembaga}`} subtitle={`Berikut adalah tampilan dari ${item.lembaga.nama_lembaga}`} onClick={() => handleShowModal(item.id)} text="Sambutan" />
                </div>
                <div className="row">
                  <div className="col-4">
                    <div className="card">
                      <div className="card-body">
                        <img style={{ maxWidth: '100%' }} src={item.url_foto_kepsek} alt="" />
                        <h3 className="text-center text-dark fw-bold mt-3">{item.nama_kepsek}</h3>
                        <p className="text-center">{item.jabatan}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="card">
                      <div className="card-body">
                        <h3 className="text-success font-weight-bold">Sambutan {item.lembaga.nama_lembaga}</h3>
                        <div style={{ textAlign: 'justify' }} className="text-dark">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: item.isi_sambutan,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>

      <Modal size="lg" show={show} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ubah Sambutan {selectedSambutan?.nama_lembaga}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedSambutan ? (
            <>
              <Form>
                <Form.Group controlId="formFile" className="mb-3">
                  <div>
                    <img src={selectedSambutan.url_foto_kepsek} alt="banner" className="img-fluid rounded-3 mb-2" style={{ maxWidth: '150px' }} />
                  </div>
                  <Alert variant="warning">
                    <span className="fw-bold">Perhatian:</span> Gambar harus memiliki orientasi potrait dengan ukuran {''}
                    <span className="fw-bold">1016px * 1352px</span> dan berformat JPEG, JPG, atau PNG dengan ukuran kurang dari 5 MB.
                  </Alert>
                  <Form.Label>
                    {' '}
                    <h4 className="fw-semibold">Ubah Foto</h4>
                  </Form.Label>
                  <Form.Control type="file" className={`form-control ${fotoKepsekError ? 'is-invalid' : ''}`} accept="image/*" onChange={handleFotoKepsek} />
                  {fotoKepsekError && <Form.Feedback type="is-valid">{fotoKepsekError}</Form.Feedback>}
                </Form.Group>
                <Form.Group controlId="formNama" className="mb-3">
                  <Form.Label>
                    <h4 className="fw-semibold">Nama</h4>
                  </Form.Label>
                  <Form.Control value={namakepsek} onChange={(e) => setNamaKepsek(e.target.value)} className={`form-control ${namaError ? 'is-invalid' : ''}`} placeholder="Masukkan nama kepala sekolah/lembaga" />
                  {<Form.Text className="text-danger">{namaError}</Form.Text>}
                </Form.Group>
                <Form.Group controlId="formJabatan" className="mb-3">
                  <Form.Label>
                    <h4 className="fw-semibold">Jabatan</h4>
                  </Form.Label>
                  <Form.Control value={jabatanKepsek} onChange={(e) => setJabatanKepsek(e.target.value)} className={`form-control ${jabatanError ? 'is-invalid' : ''}`} placeholder="Masukkan jabatan kepala sekolah/lembaga" />
                  {<Form.Text className="text-danger">{jabatanError}</Form.Text>}
                </Form.Group>

                <Form.Group controlId="formSambutan" className="mb-3">
                  <Form.Label>
                    <h4 className="fw-semibold">Isi Sambutan</h4>
                  </Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={isiSambutan}
                    className={`form-control ${isiSambutanError ? 'is-invalid' : ''}`}
                    config={{
                      placeholder: 'Masukkan isi sambutan kepala sekolah/lembaga',
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setIsiSambutan(data);
                    }}
                  />
                  {isiSambutanError && <Form.Text className="text-danger">{isiSambutanError}</Form.Text>}
                </Form.Group>
              </Form>
            </>
          ) : (
            <>
              <p>Data tidak ditemukan</p>
              <p>Loading</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Batal
          </Button>
          <Button variant="primary" onClick={handleSave} disabled={loading}>
            {loading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                Menyimpan
              </>
            ) : (
              'Simpan'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SambutanLembaga;
